import { withMultiRegionProperties } from "@/shared/wrappers/withMultiRegion";

const formatsZipCodeRegion = {
  BR: "#####-###",
  US: "#####-####",
  UK: [
    "A# #AA",     
    "A## #AA",   
    "AA# #AA",   
    "AA## #AA",   
    "A#A #AA",   
    "AA#A #AA",  
  ],
};

function selectUKPattern(value) {
  const rawValue = value.replace(/\W/g, "");
  const patterns = formatsZipCodeRegion.UK;
  
  if (rawValue.length <= 3) {
    return patterns[0]; // A# #AA
  } else if (rawValue.length === 4) {
    return patterns[0]; // A# #AA should still apply for 4 characters
  } else if (rawValue.length === 5) {
    return patterns[0]; // Keep A# #AA for 5 characters
  } else if (rawValue.length === 6) {
    return rawValue[2] >= 'A' && rawValue[2] <= 'Z' ? patterns[4] : patterns[2]; // A#A #AA or AA# #AA
  } else if (rawValue.length === 7) {
    return patterns[5]; // AA#A #AA for 7 characters
  }
  
  return patterns[0]; 
}


export default function formatZipCode(value, region) {
  if (value === "") {
    return "";
  }

  let formatPattern;
  
  if (region === "UK") {
    formatPattern = selectUKPattern(value);
  } else {
    formatPattern = withMultiRegionProperties(formatsZipCodeRegion);
  }

  const rawValue = value.replace(/\W/g, ""); 

  if (!formatPattern) {
    return value;
  }

  let formattedValue = "";
  let index = 0;

  for (let char of formatPattern) {
    if (char === "#" || char === "A") {
      formattedValue += rawValue[index] || "";
      index++;
    } else {
      formattedValue += char;
    }
    if (index >= rawValue.length) {
      break;
    }
  }

  return formattedValue;
}