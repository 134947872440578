import React, { createRef } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "@/shared/components/atoms/AsyncSelect";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import Loader from "react-loader-spinner";
import AddEMRCredentail from "./addEditEMR";
import AddFacilityGroup from "./addFacilityGroup";

import PopupModal from "../modal/popupmodal";
import CheckPermission from "../../General/CheckPermission";
import RBAC, {
  ModulesName,
  UserActions,
} from "../../General/roleModuleActions";
import NewBrightreePhysician from "../physician/newbrightreephysician";
import { Animated } from "react-animated-css";
import { injectIntl } from "react-intl";
import UpsertFacilityZipFieldMultiRegion from "@/shared/components/upsertFacility/upsertFacilityZipField";

var emrModal, addEMR = null;

class AddFacility extends React.Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.state = {
      IsLoading: false,
      physicianList: [],
      physician: null,
      physiciansInputValue: "",
      selectOrgOptions: [],
      physicianList: [],
      selectOrgChainOptions: [],
      selectFacilityTypeOptions: [],
      addBtPhysicianModal: false,
      selectProductOptions: [],
      selectGroupPurchasingOptions: [],
      selectEhrvendorOption: [],
      selectWarehouseOption: [],
      selectClinicianOptions: [],
      manager: "",
      vicePresident: "",
      states: [],
      formValidated: false,
      isHeadquarters: false,
      emr: {
        id: null,
        name: "",
        description: "",
        loginId: "",
        password: "",
        isCredentailUpdate: true,
      },
      Facilty: {
        fkOrgId: null,
        fkChainId: null,
        facilityName: "",
        fkFacilityTypeId: null,
        npi: "",
        fkProductLineId: null,
        numberofBeds: null,
        fkGroupPurchasingOrg: null,
        websiteAddress: "",
        partBrecPerson: "",
        partBrecPersonPhone: "",
        centerSupplyPersonPhone: "",
        partBrecPersonEmail: "",
        centerSupplyPersonEmail: "",
        centralSupplyPerson: "",
        logoUrl: "",
        emrpassword: "",
        emrlogin: "",
        fkAssignedNurseId: null,
        fkEhrvendorId: null,
        vendorFacilityId: "",
        fkWarehouseId: null,
        physicianName: "",
        fkPhysicianId: null,
        medicarePartBbyGentell: false,
        outProgram: false,
        isThisFacilityFollowingTheCycle: false,
        isEcp: false,
        singingForAllCmns: false,
        canAddAssessment: false,
        memo: "",
        fkClinicianId: null,
        active: true,
        address: {
          // AddressId:null,
          addressLine1: "",
          addressLine2: "",
          city: "",
          stateId: null,
          countryId: 1,
          zip: "",
          phone1: "",
          email: "",
          mobile1: "",
          fax: "",
        },
        physicianViewModel: {
          physicianId: 0,
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          suffix: "",
          physicianNote: "",
          npi: "",
          stateMedicaidId: "",
          licenseNo: "",
          TaxonomyCode: "",
          brightreeId: 0,
          doctorGroupId: 0,

          address: {
            addressId: 0,
            addressLine1: "",
            addressLine2: "",
            city: "",
            sateId: 1,
            countryId: 1,
            zip: "",
            phone1: "",
            email: "",
            mobile1: "",
            fax: "",
          },
        },
      },
      countryList: [],
    };
    this.emailError = false;
    this.facilityGroupModal = createRef();
  }
  componentDidMount() {
    this.getAllFacilityGroup();
    this.getAllLookupsValues();
    this.getAllState();
    this.getAllActiveEMR();
    this.setState({ IsLoading: true });
    this.GetCountryList();
  }
  getAllFacilityGroup = () => {
    Api.getRequest(Api.Uri_GetOrganization).then((response) => {
      const data = response.data.result;
      let optionTemplate = data.map((v) => (
        <option key={v.key} value={v.key}>
          {v.value}
        </option>
      ));
      this.setState({ selectOrgOptions: optionTemplate });
    });
  };

  async getAllLookupsValues() {
    this.setState({ IsLoading: false });

    var facilityType = await Common.getLookupOptions(
      Common.LookupsFK.Fk_FacilityTypeID
    );
    this.setState({ selectFacilityTypeOptions: facilityType });

    var productLine = await Common.getLookupOptions(
      Common.LookupsFK.Fk_ProductLineID
    );
    this.setState({ selectProductOptions: productLine });

    // var selectGroupPurchasingOptions = await Common.getLookupOptions(
    //   Common.LookupsFK.OrganizationID
    // );
    // this.setState({
    //   selectGroupPurchasingOptions: selectGroupPurchasingOptions,
    // });

    var selectEhrvendorOption = await Common.getLookupOptions(
      Common.LookupsFK.Fk_EHRVendorID
    );
    this.setState({ selectEhrvendorOption: selectEhrvendorOption });

    var selectWarehouseOption = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WarehouseID
    );
    this.setState({ selectWarehouseOption: selectWarehouseOption });

    var clinicain = await Api.getRequest(
      Api.Uri_GetUserByRoleID,
      `?id=${Common.LookupsFK.Fk_RoleClinicianID}`
    );
    const clinicainData = clinicain.data.result;
    let clinicainOption = clinicainData.map((v) => (
      <option key={v.key} value={v.key}>
        {v.value}
      </option>
    ));
    this.setState({ selectClinicianOptions: clinicainOption });
  }
  getChainByOrgID(e) {
    Common.updateInputValueInState(this.state.Facilty, e, this, this.state);
    var orgID = this.state.Facilty.fkOrgId;
    Api.getRequestById(Api.Uri_GetChainByOrgID, orgID).then((response) => {
      const data = response.data.result;
      let optionTemplate = data.map((v) => (
        <option key={v.key} value={v.key}>
          {v.value}
        </option>
      ));
      this.setState({ selectOrgChainOptions: optionTemplate });
    });
  }
  handleCloseAdd = () => {
    this.setState({ addBtPhysicianModal: false });
  };
  handleShowAddBrightreePhysician = () => {
    this.setState({
      addBtPhysicianComponent: (
        <NewBrightreePhysician
          close={this.handleCloseAdd}
          selectPhysician={this.selectPhysician}
        />
      ),
    });
    this.setState({ addBtPhysicianModal: true });
  };
  selectPhysician = (obj) => {
    // Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, obj.brightreeId, "fkPrimaryPhysicianId")
    // Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, (obj.lastName+" "+obj.firstName), "primaryPhysicianName")
    var _selectedPhysician = this.state.Facilty;
    _selectedPhysician.physicianViewModel = obj;
    _selectedPhysician.fkPhysicianId = null;
    _selectedPhysician.physicianName =
      Common.replaceNullWithString(obj.lastName) +
      " " +
      Common.replaceNullWithString(obj.firstName);
    this.setState({ Facilty: _selectedPhysician });
    Common.showSuccessAlert(
      "Physician selected successfully",
      "success",
      "Success!",
      3000
    );
  };
  //#region Get States
  getAllState() {
    Api.getRequest(Api.Uri_GetState)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ states: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Facility", "NewFacility");
      });
  }
  setOptionsUI(options) {
    return options.map((option) => (
      <option key={option.key} value={option.key}>
        {option.value}
      </option>
    ));
  }
  //#endregion
  onSubmit() {
    if (this.form.checkValidity() === false) {
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      const obj = this.state.Facilty;
      var postalCode = Common.isValidZipCode(obj.address.zip);
      if (!postalCode && obj.address.zip != "") {
          Common.showSuccessAlert(this.intl.formatMessage({ id: "EDIT_FACILITY.THE_VALUE_IS_NOT_A_VALID_ZIP_CODE" }),
          "warning",
          "Warning!",
          3000
        );
        return false;
      }
      this.setState({ IsLoading: false });
      obj.centerSupplyPersonPhone =
        Common.replaceNullWithString(obj.centerSupplyPersonPhone) != ""
          ? obj.centerSupplyPersonPhone.replace(/[^0-9]/g, "").substr(0, 10)
          : null;
      obj.partBrecPersonPhone =
        Common.replaceNullWithString(obj.partBrecPersonPhone) != ""
          ? obj.partBrecPersonPhone.replace(/[^0-9]/g, "").substr(0, 10)
          : null;

      obj.address.phone1 =
        Common.replaceNullWithString(obj.address.phone1) != ""
          ? obj.address.phone1.replace(/[^0-9]/g, "").substr(0, 10)
          : null;

      obj.address.fax =
        Common.replaceNullWithString(obj.address.fax) != ""
          ? obj.address.fax.replace(/[^0-9]/g, "").substr(0, 10)
          : null;

      if (
        this.state.Facilty.email &&
        !Common.isValidEmail(this.state.Facilty.email)
      ) {
        Common.showSuccessAlert(
          "Please enter a valid email address",
          "warning",
          "Warning!",
          3000
        );
        this.setState({ IsLoading: true });
        return false;
      }

      Api.postRequest(Api.Uri_AddFacility, obj).then((res) => {
        if (res.data.success) {
          this.props.onSaveAPI();
          this.setState({ IsLoading: true });
          Common.showSuccessAlertAutoClose(
            "Data Saved Successfully",
            "success",
            "Success!",
            3000
          );
        } else {
          Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
        }
      });
    }
  }

  setNpiValue = (e) => {
    var npi = e.target.value;
    if (npi.length < 11) {
      Common.updateInputValueInState(this.state.Facilty, e, this, this.state);
    }
  };
  onProductSpecialistChange = (e) => {
    Common.updateInputValueInState(this.state.Facilty, e, this, this.state);
    this.setState({ manager: "", vicePresident: "" });

    if (e.target.value > 1) {
      this.getRegionalManagerAndVicePresident(e.target.value);
    }
  };
  getRegionalManagerAndVicePresident = (userID) => {
    Api.getRequestQry(
      Api.Uri_GetRegionalManagerAndVicePresident,
      "?userID=" + userID
    ).then((response) => {
      if (response.data.result.length > 0) {
        for (var i = 0; i < response.data.result.length; i++) {
          var usr = response.data.result[i];
          if (usr.managerType == "Manager") {
            this.setState({ manager: usr.lastName + " " + usr.firstName });
          } else if (usr.managerType == "VicePresident") {
            this.setState({
              vicePresident: usr.lastName + " " + usr.firstName,
            });
          }
        }
      }

      // this.setState({ selectOrgOptions: optionTemplate });
    });
  };
  getAllActiveEMR() {
    Api.getRequestQry(Api.apiUrls.getAllEMR, "?isActive=" + 1)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({
                selectGroupPurchasingOptions: res.data.result,
              });
            }
          }
        }
      })
      .catch((ex) => {});
  }
  setAllEMR = (users) => {
    return users.map((usr) => (
      <option key={usr.id} value={usr.id}>
        {usr.name}
      </option>
    ));
  };
  showEditEMR = async (id) => {
    var findEmr = this.state.selectGroupPurchasingOptions.find(
      (x) => x.id == this.state.Facilty.fkGroupPurchasingOrg
    );
    // await setEMR(obj);
    if (findEmr != undefined) {
      findEmr.isCredentailUpdate = true;
      this.setState({ emr: findEmr });
      emrModal.showModal();
    }
  };
  onEMRFormSubmit = () => {
    emrModal.hideModal();
    //getHeaderValue() ;
    Common.showSuccessAlertAutoClose(
      "EMR Saved successful",
      "success",
      "Success!",
      3000
    );
    this.getAllActiveEMR();
  };
  SyncFacilityGroup = () => {
    Api.getRequest(Api.apiUrls.fetchFacilityGroups)
      .then((res) => {
        if (res.data.statusCode == 200) {
          Common.showSuccessAlert(
            "Facility Group Fetch Successful",
            "success",
            "Success!",
            3000
          );
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Facility", "NewFacility");
      });
  };

  loadPhysicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {
        var { data } = await Api.getRequestQry(
          Api.apiUrls.doctorsSearch,
          `?searchTerm=${inputValue}`
        );
        if (data.success) {
          let orgList = [];
          if (data.result.length) {
            orgList = data.result;
          }
          this.setState({ physicianList: orgList });
          // setOrganizations(orgList)
          return orgList.map((e) => {
            return {
              label: e.lastName + " " + e.firstName,
              value: e.brightreeId,
            };
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  setPhysicianInputValue = async (value) => {
    // this.setState({physiciansInputValue:value})
    this.setState({ physiciansInputValue: value });
  };
  setPhysician = (physician) => {
    debugger;
    this.setState({ physician: physician });
    var _selectedPhysician = this.state.Facilty;
    if (physician != null) {
      var obj = this.state.physicianList.find(
        (x) => x.brightreeId == physician.value
      );
      _selectedPhysician.physicianViewModel = obj;
      _selectedPhysician.fkPhysicianId = null;
      _selectedPhysician.physicianName =
        Common.replaceNullWithString(obj.lastName) +
        " " +
        Common.replaceNullWithString(obj.firstName);
    } else {
      _selectedPhysician.physicianViewModel = null;
      _selectedPhysician.fkPhysicianId = null;
      _selectedPhysician.physicianName = "";
    }

    this.setState({ Facilty: _selectedPhysician });
  };

  GetCountryList() {
    Api.getRequest(Api.Uri_GetCountry)
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ countryList: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "New Facility", "GetCountryList");
      });
  }
  render() {
    return (
      <>
        {this.state.addBtPhysicianModal && (
          <Animated animationIn="fadeInLeft" animationInDuration={1000}>
            {this.state.addBtPhysicianComponent}
          </Animated>
        )}
        {!this.state.addBtPhysicianModal && (
          <Animated animationIn="fadeInLeft" animationInDuration={1000}>
            {this.state.IsLoading == true ? (
              <div>
                <Form
                  ref={(ref) => {
                    this.form = ref;
                  }}
                  validated={this.state.formValidated}
                  onSubmit={(e) => this.onSubmit(e)}
                >
                  <>
                    <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                      <legend className="text-primary fw-500">
                        {this.intl.formatMessage({
                          id: "ADDFACILITY.BASIC_INFORMATION",
                        })}
                      </legend>
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="ThisFacilityBelongsTo"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.OWNERSHIP_HEADQUARTERS",
                              })}
                              <button
                                type="button"
                                className="ml-2  btn  btn-success"
                                onClick={() => {
                                  if (this.facilityGroupModal.current) {
                                    this.facilityGroupModal.current.showModal();
                                    this.setState({ isHeadquarters: false });
                                  }
                                }}
                              >
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.ADD_BUTTON",
                                })}
                              </button>
                            </label>
                            {/* <Select options={this.state.selectOrgOptions} onChange={e => this.getChainByOrgID(e)} /> */}
                            <div className="input-group">
                              <select
                                className="form-control form-control-sm"
                                id="fkOrgId"
                                name="fkOrgId"
                                onChange={(e) => this.getChainByOrgID(e)}
                                defaultValue={this.state.Facilty.fkOrgId}
                              >
                                <option></option>
                                {this.state.selectOrgOptions}
                              </select>
                              <CheckPermission
                                userRole={RBAC.Role_Gentell_Administrator}
                                yes={() => (
                                  <>
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.SyncFacilityGroup();
                                        }}
                                        className="btn btn-secondary"
                                      >
                                        {this.intl.formatMessage({
                                          id: "ADDFACILITY.SYNC_BUTTON",
                                        })}
                                      </button>
                                    </div>
                                  </>
                                )}
                                no={() => null}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="ThisFacilityBelongsTo"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.PURCHASING_GROUP_CHAIN",
                              })}
                              <button
                                type="button"
                                className="ml-2  btn  btn-success"
                                onClick={() => {
                                  if (this.facilityGroupModal.current) {
                                    this.facilityGroupModal.current.showModal();
                                    this.setState({ isHeadquarters: true });
                                  }
                                }}
                              >
                                Add
                              </button>
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="fkChainId"
                              defaultValue={this.state.Facilty.fkChainId}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                            >
                              <option></option>
                              {this.state.selectOrgChainOptions}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="FacilityName"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.FACILITY_NAME",
                              })}
                            </label>
                            <input
                              type="text"
                              id="FacilityName"
                              name="facilityName"
                              required
                              defaultValue={this.state.Facilty.facilityName}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="FacilityType"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.FACILITY_TYPE",
                              })}
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="fkFacilityTypeId"
                              required
                              defaultValue={this.state.Facilty.fkFacilityTypeId}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                            >
                              <option></option>
                              {this.state.selectFacilityTypeOptions}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label className="form-label" htmlFor="NPINumber">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.NPI_NUMBER",
                              })}
                            </label>
                            <input
                              type="number"
                              id="NPINumber"
                              name="npi"
                              defaultValue={this.state.Facilty.npi}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onChange={(e) => this.setNpiValue(e)}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="FacilityType"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.PRODUCT_LINE",
                              })}
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="fkProductLineId"
                              required
                              defaultValue={this.state.Facilty.fkProductLineId}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                            >
                              <option></option>
                              {this.state.selectProductOptions}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="NumberofBeds"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.NUMBER_OF_BEDS",
                              })}
                            </label>
                            <input
                              type="number"
                              id="NumberofBeds"
                              name="numberofBeds"
                              // required
                              defaultValue={this.state.Facilty.numberofBeds}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="MemberofGroupPurchasingOrg."
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.EMR_SYSTEM",
                              })}
                            </label>
                            <div className="input-group">
                              <select
                                className="form-control form-control-sm"
                                name="fkGroupPurchasingOrg"
                                defaultValue={
                                  this.state.Facilty.fkGroupPurchasingOrg
                                }
                                onChange={(e) =>
                                  Common.updateInputValueInState(
                                    this.state.Facilty,
                                    e,
                                    this,
                                    this.state
                                  )
                                }
                              >
                                <option></option>
                                {/* {this.state.selectGroupPurchasingOptions} */}
                                {this.setAllEMR(
                                  this.state.selectGroupPurchasingOptions
                                )}
                              </select>
                              {/* <CheckPermission
                              userRole={RBAC.Role_Gentell_Administrator}
                              yes={() => (
                                <>
                                  <div className="input-group-append"><button
                                    type="button"
                                    disabled={this.state.Facilty.fkGroupPurchasingOrg > 0 ? false : true}
                                    onClick={() => { this.showEditEMR() }}
                                    className="btn btn-secondary">Edit</button>
                                  </div>
                                </>
                              )}
                              no={() => null}
                            /> */}
                            </div>
                          </div>
                        </div>
                        {this.state.Facilty.fkGroupPurchasingOrg == 3 && (
                          <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="WebSiteAddress"
                              >
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.ACCOUNT_NAME",
                                })}
                              </label>
                              <input
                                name="websiteAddress"
                                required
                                onChange={(e) =>
                                  Common.updateInputValueInState(
                                    this.state.Facilty,
                                    e,
                                    this,
                                    this.state
                                  )
                                }
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        )}
                        <CheckPermission
                          userRole={RBAC.Role_Gentell_Administrator}
                          yes={() => (
                            <>
                              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="doB">
                                    {this.intl.formatMessage({
                                      id: "ADDFACILITY.USER_ID",
                                    })}
                                  </label>
                                  <input
                                    type="text"
                                    id="emrlogin"
                                    className="form-control form-control-sm"
                                    name="emrlogin"
                                    defaultValue={this.state.Facilty.emrlogin}
                                    onChange={(e) =>
                                      Common.updateInputValueInState(
                                        this.state.Facilty,
                                        e,
                                        this,
                                        this.state
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="doB">
                                    {this.intl.formatMessage({
                                      id: "ADDFACILITY.PASSWORD",
                                    })}
                                  </label>
                                  <input
                                    type="text"
                                    id="emrpassword"
                                    className="form-control form-control-sm"
                                    name="emrpassword"
                                    defaultValue={
                                      this.state.Facilty.emrpassword
                                    }
                                    onChange={(e) =>
                                      Common.updateInputValueInState(
                                        this.state.Facilty,
                                        e,
                                        this,
                                        this.state
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          no={() => null}
                        />
                        <div className="col-sm-2 col-md-2 col-lg-4 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="Shipsfromthiswarehouse:"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.SHIPS_FROM_WAREHOUSE",
                              })}
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="fkWarehouseId"
                              defaultValue={this.state.Facilty.fkWarehouseId}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                            >
                              <option></option>
                              {this.state.selectWarehouseOption}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="FacilityType"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.WOUND_AND_PRODUCT_SPECIALIST",
                              })}
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="fkClinicianId"
                              defaultValue={this.state.Facilty.fkClinicianId}
                              onChange={(e) =>
                                this.onProductSpecialistChange(e)
                              }
                            >
                              <option></option>
                              {this.state.selectClinicianOptions}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="FacilityType"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.REGIONAL_MANAGER",
                              })}
                            </label>
                            <input
                              type="text"
                              disabled
                              defaultValue={this.state.manager}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="FacilityType"
                            >
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.VICE_PRESIDENT",
                              })}
                            </label>
                            <input
                              type="text"
                              disabled
                              defaultValue={this.state.vicePresident}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                      <legend className="text-primary fw-500">
                        {this.intl.formatMessage({
                          id: "ADDFACILITY.ADDRESS_INFORMATION",
                        })}
                      </legend>
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Memo">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.ADDRESS_LINE_1",
                              })}
                            </label>
                            <input
                              type="text"
                              id="address1"
                              name="addressLine1"
                              defaultValue={
                                this.state.Facilty.address.addressLine1
                              }
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            ></input>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Memo">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.ADDRESS_LINE_2",
                              })}
                            </label>
                            <input
                              type="text"
                              id="addressLine2"
                              name="addressLine2"
                              defaultValue={
                                this.state.Facilty.address.addressLine2
                              }
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            ></input>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="City">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.CITY",
                              })}
                            </label>
                            <input
                              type="text"
                              id="City"
                              name="city"
                              defaultValue={this.state.Facilty.address.city}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="State">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.STATE",
                              })}
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="stateId"
                              required
                              defaultValue={this.state.Facilty.address.stateId}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                            >
                              <option></option>
                              {this.setOptionsUI(this.state.states)}
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Postal Code */}
                      <div className="row">
                        <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="zip">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.POSTAL_CODE",
                              })}
                            </label>
                            <UpsertFacilityZipFieldMultiRegion
                              that={this}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Country">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.COUNTRY",
                              })}
                            </label>
                            <select
                              className="form-control form-control-sm"
                              name="countryId"
                              defaultValue={
                                this.state.Facilty.address.countryId
                              }
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              id="State"
                            >
                              <option></option>
                              {this.setOptionsUI(this.state.countryList)}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Phone1">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.PHONE",
                              })}
                            </label>
                            <input
                              type="phone"
                              id="Phone1"
                              name="phone1"
                              value={Common.getFormattedPhoneNum(
                                this.state.Facilty.address.phone1
                              )}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Phone1">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.FAX",
                              })}
                            </label>
                            <input
                              type="text"
                              id="Fax"
                              name="fax"
                              value={Common.getFormattedPhoneNum(
                                this.state.Facilty.address.fax
                              )}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty.address,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="Phone1">
                    Email
                  </label>
                  <input
                    type="text"
                    id="Phone1"
                    name="email"
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        this.state.Facilty.address,
                        e,
                        this,
                        this.state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div> */}
                        {/* End Address */}
                      </div>
                    </fieldset>
                    <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                      <legend className="text-primary fw-500">
                        {this.intl.formatMessage({
                          id: "ADDFACILITY.OTHER_INFORMATION",
                        })}
                      </legend>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                          <ul className="list-unstyled my-5 border-dark border-md-0 border-right-lg">
                            <li className="mb-3">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="medicarePartBbyGentell"
                                  checked={
                                    this.state.Facilty.medicarePartBbyGentell
                                  }
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="m-1"
                                />
                                <span className="mr-3" />
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.WOUND_CARE_PART_B",
                                })}
                              </label>
                            </li>
                            <li className="mb-3">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="outProgram"
                                  checked={this.state.Facilty.outProgram}
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="m-1"
                                />
                                <span className="mr-3" />
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.OUTS_PART_B",
                                })}
                              </label>
                            </li>
                            {this.state.Facilty.outProgram && (
                              <div className="pl-3 pr-8 mb-3">
                                <select
                                  className="form-control form-control-sm"
                                  name="fkOutProgramWarehouse"
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  id="fkOutProgramWarehouse"
                                  value={
                                    this.state.Facilty.fkOutProgramWarehouse
                                  }
                                >
                                  <option value={33}>Bristol</option>
                                  <option value={34}>Chicago</option>
                                </select>
                              </div>
                            )}
                            <li className="mb-3">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="IsEcp"
                                  checked={this.state.Facilty.IsEcp}
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="m-1"
                                />
                                <span className="mr-3" />
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.EQUAL_CARE_PROGRAM",
                                })}
                              </label>
                            </li>
                            <li className="mb-3">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="isThisFacilityFollowingTheCycle"
                                  checked={
                                    this.state.Facilty
                                      .isThisFacilityFollowingTheCycle
                                  }
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="m-1"
                                />
                                <span className="mr-3" />
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.ENTERAL_PROGRAM",
                                })}
                              </label>
                            </li>
                            <li className="mb-3">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="canAddAssessment"
                                  checked={this.state.Facilty.canAddAssessment}
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="m-1"
                                />
                                <span className="mr-3" />
                                {this.intl.formatMessage({
                                  id: "ADDFACILITY.FACILITY_USE_OF_FASTCARE",
                                })}
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                          <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="PartBReceivingPerson"
                                >
                                  {this.intl.formatMessage({
                                    id: "ADDFACILITY.PART_B_RECEIVING_PERSON",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  id="PartBReceivingPerson"
                                  name="partBrecPerson"
                                  defaultValue={
                                    this.state.Facilty.partBrecPerson
                                  }
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Phone1">
                                  {this.intl.formatMessage({
                                    id: "ADDFACILITY.RECEIVING_PERSON_PHONE",
                                  })}
                                </label>
                                <input
                                  type="phone"
                                  id="partBrecPersonPhone"
                                  name="partBrecPersonPhone"
                                  value={Common.getFormattedPhoneNum(
                                    this.state.Facilty.partBrecPersonPhone
                                  )}
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Phone1">
                                  {this.intl.formatMessage({
                                    id: "ADDFACILITY.RECEIVING_PERSON_EMAIL",
                                  })}
                                </label>
                                <input
                                  type="phone"
                                  id="partBrecPersonEmail"
                                  name="partBrecPersonEmail"
                                  defaultValue={
                                    this.state.Facilty.partBrecPersonEmail
                                  }
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="Central Supply Person"
                                >
                                  {this.intl.formatMessage({
                                    id: "ADDFACILITY.CENTRAL_SUPPLY_PERSON",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  id="Stree2:"
                                  name="centralSupplyPerson"
                                  defaultValue={
                                    this.state.Facilty.centralSupplyPerson
                                  }
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Phone1">
                                  {this.intl.formatMessage({
                                    id:
                                      "ADDFACILITY.CENTRAL_SUPPLY_PERSON_PHONE",
                                  })}
                                </label>
                                <input
                                  type="phone"
                                  id="centerSupplyPersonPhone"
                                  name="centerSupplyPersonPhone"
                                  value={Common.getFormattedPhoneNum(
                                    this.state.Facilty.centerSupplyPersonPhone
                                  )}
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                              <div className="form-group">
                                <label className="form-label" htmlFor="Phone1">
                                  {this.intl.formatMessage({
                                    id:
                                      "ADDFACILITY.CENTRAL_SUPPLY_PERSON_EMAIL",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  id="centerSupplyPersonEmail"
                                  name="centerSupplyPersonEmail"
                                  defaultValue={
                                    this.state.Facilty.centerSupplyPersonEmail
                                  }
                                  onChange={(e) =>
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    )
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="row"> */}
                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="AssignedNurse">
                        Assigned Nurse
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="AssignedNurse"
                        name="fkAssignedNurseId"
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Facilty,
                            e,
                            this,
                            this.state
                          )
                        }
                      >
                        <option>Test Test</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div> */}
                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="EMRSystem">
                        EHR System{" "}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        name="fkEhrvendorId"
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Facilty,
                            e,
                            this,
                            this.state
                          )
                        }
                      >
                        {this.state.selectEhrvendorOption}
                      </select>
                    </div>
                  </div> */}

                          <div className="row">
                            <div className="col-4">
                              <div className="form-group">
                                <label className="form-label" htmlFor="gender">
                                  {this.intl.formatMessage({
                                    id:
                                      "ADDFACILITY.MEDICAL_OR_PHYSICIAN_SIGNING",
                                  })}
                                </label>
                                {/* <input
                                    type="text"
                                    id="physicianName"
                                    className="form-control form-control-sm"
                                    name="physicianName"
                                    disabled
                                    defaultValue={Common.replaceNullWithString(
                                      this.state.Facilty.physicianName)
                                    }
                                  /> */}

                                <AsyncSelect
                                  // defaultOptions
                                  // menuPosition="fixed"
                                  placeholder={this.intl.formatMessage({
                                    id: "ADDFACILITY.SELECT",
                                  })}
                                  name="organizationID"
                                  inputValue={this.state.physiciansInputValue}
                                  onInputChange={this.setPhysicianInputValue}
                                  value={this.state.physician}
                                  onChange={(obj) => {
                                    this.setPhysician(obj);
                                  }}
                                  loadOptions={this.loadPhysicians}
                                  isClearable
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group mt-7">
                                <label className="form-label" htmlFor="email">
                                  {this.intl.formatMessage({
                                    id: "ADDFACILITY.EMAIL",
                                  })}
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control form-control-sm"
                                  value={this.state.Facilty.email}
                                  onChange={(e) => {
                                    Common.updateInputValueInState(
                                      this.state.Facilty,
                                      e,
                                      this,
                                      this.state
                                    );
                                    if (
                                      e.target.value &&
                                      !Common.isValidEmail(e.target.value)
                                    ) {
                                      this.setState({ emailError: true });
                                    } else {
                                      this.setState({ emailError: false });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group mt-8">
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    name="singingForAllCmns"
                                    checked={
                                      this.state.Facilty.singingForAllCmns
                                    }
                                    onChange={(e) =>
                                      Common.updateInputValueInState(
                                        this.state.Facilty,
                                        e,
                                        this,
                                        this.state
                                      )
                                    }
                                    className="m-1"
                                  />
                                  <span className="mr-3" />
                                  {this.intl.formatMessage({
                                    id: "ADDFACILITY.SIGNING_FOR_ALL_CMNS",
                                  })}
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div className="form-group">
                              <label className="form-label" htmlFor="Logo">
                                Logo
                              </label>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input cursor-pointer"
                                    name="logoUrl"
                                    onChange={(e) =>
                                      Common.updateInputValueInState(
                                        this.state.Facilty,
                                        e,
                                        this,
                                        this.state
                                      )
                                    }
                                    id="Logo"
                                    aria-describedby="Logo"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="inputGroupFile04"
                                  >
                                    Choose file
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="Memo">
                              {this.intl.formatMessage({
                                id: "ADDFACILITY.MEMO",
                              })}
                            </label>
                            <textarea
                              className="form-control"
                              name="memo"
                              defaultValue={this.state.Facilty.memo}
                              onChange={(e) =>
                                Common.updateInputValueInState(
                                  this.state.Facilty,
                                  e,
                                  this,
                                  this.state
                                )
                              }
                              id="Memo"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </>
                </Form>
                {
                  <PopupModal
                    size="lg"
                    title="Add EMR"
                    module={ModulesName.Module_Setup}
                    action={UserActions.Create}
                    buttonPress={() => addEMR.onSubmit()}
                    ref={(ref) => {
                      emrModal = ref;
                    }}
                  >
                    {" "}
                    <AddEMRCredentail
                      // ref={(ref) => {
                      //   addEMR = ref;
                      // }}
                      onSaveAPI={() => this.onEMRFormSubmit()}
                      emr={this.state.emr}
                    />{" "}
                  </PopupModal>
                }
                <PopupModal
                  size="lg"
                  title="Facility Group"
                  module={ModulesName.Module_Setup}
                  action={UserActions.Create}
                  buttonPress={() => {
                    if (this.facilityGroupModal.current) {
                      this.facilityGroupModal.current.onSubmit()
                    }
                  }}
                  ref={this.facilityGroupModal}
                >
                  {" "}
                  <AddFacilityGroup
                    ref={this.facilityGroupModal}
                    onSaveAPI={() => this.onEMRFormSubmit()}
                    isHeadquarters={this.state.isHeadquarters}
                    selectOrgOptions={this.state.selectOrgOptions}
                  />{" "}
                </PopupModal>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Loader type="Puff" color="#00BFFF" height={50} width={50} />
              </div>
            )}
          </Animated>
        )}
      </>
    );
  }
}

export default injectIntl(AddFacility, {
  forwardRef: true,
});
