import React from "react";
import { injectIntl } from "react-intl";
import ReactSelectAsyncSelect from "react-select/async";

function AsyncSelect({ intl, ...props }) {
  return (
    <ReactSelectAsyncSelect
      {...props}
      loadingMessage={() =>
        intl.formatMessage({ id: "INPUT_SELECT.LOADING" })
      }
    />
  );
}

export default injectIntl(AsyncSelect);
