import axios from "axios";
import * as Common from "../General/common";
import { ApiBaseUrl, apiUrls as ApiUrls } from "../constants/endpoints";
export const LOGIN_URL = ApiBaseUrl + "Account/Authenticate";
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot-password";
export const ME_URL = "me";

const getAuthToken = async () => {
  var token = JSON.parse(localStorage.getItem("gentell-fastcare-authToken"));

  if (token === null || token === "") return "";

  const bearerToken = `Bearer ${token}`;

  return bearerToken;
};

export const axiosInstance = axios.create();

function Logout(errorCode = "ex") {
  localStorage.removeItem("gentell-fastcare-authToken");
  localStorage.removeItem("gentell-fastcare-authUser");
  window.location.assign("/logout?se=" + errorCode);
}

axiosInstance.interceptors.request.use(
  async function (config) {
    const token = await getAuthToken();

    if (token != null) {
      config.headers.Authorization = token;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // // debugger
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    try {
      if (error.response.status === 401) Logout();
    } catch (ex) {
      const hasToken = localStorage.getItem("gentell-fastcare-authToken");
      if (hasToken) {
        Logout("apid");
        Common.LogError(ex, "Api Call in api.js file", "Intercepter");
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);



export function login(email, password) {
  axios.post(LOGIN_URL, {
    Username: email,
    Password: password,
  });
}
export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}
export function getUserByToken() {
  return axios.get(ME_URL);
}
export function getRequest(url, bindBaseUrl = true) {
  return axiosInstance.get(bindBaseUrl ? ApiBaseUrl + url : url);
}
export function getRequestById(url, id) {
  return axiosInstance.get(ApiBaseUrl + url + "?id=" + id);
}

export function getRequestQry(url, stringQuery) {
  return axiosInstance.get(ApiBaseUrl + url + stringQuery);
}

export function postRequest(url, requestParameters, bindBaseUrl = true) {
  return axiosInstance.post(
    bindBaseUrl ? ApiBaseUrl + url : url,
    requestParameters
  );
}

export function putRequest(url, requestParameters) {
  if (requestParameters) {
    return axiosInstance.put(ApiBaseUrl + url, requestParameters);
  } else {
    return axiosInstance.put(ApiBaseUrl + url);
  }
}
// export function changePassword(dataObj) {
//   return axios.put(CHANGE_PASSWORD, dataObj);
// }

//DropDown Constants
//#region  Lookup list Urls
export const Uri_GetLookupValuesByHeaderName = "DropDown/GetDropdownByHeaderName";
export const Uri_GetDropdown = "DropDown/GetDropHeader";
export const Uri_GetHeaderValuesByID = "DropDown/GetTaxValueByID";
export const Uri_GetLookupValuesByFkId = "DropDown/GetDropDownValue";
export const Uri_GetWoundType = "DropDown/GetWoundType";
export const Uri_GetAllItemfrequencies = "DropDown/GetAllItemfrequencies";

export const Uri_GetAllICDDropDownList = "DropDown/GetAllICDDropDownList";
export const Uri_GetAllWoundType = "DropDown/GetAllWoundType";


export const Uri_GetBodyRegion = "DropDown/GetBodyRegion";

export const Uri_GetChainByOrgID = "DropDown/GetChainByOrgId";
export const Uri_GetOrganization = "DropDown/GetOrganization";
export const Uri_GetUserByRoleID = "DropDown/GetUserByRoleID";

export const Uri_AddHeaderValues = "DropDown/create";
export const Uri_EditHeaderValues = "DropDown/update";
export const Uri_GetLookUp_PatientNoteReasonFetchAll = "DropDown/GetLookUp_PatientNoteReasonFetchAll";
export const Uri_GetResidentByFacilityId = "DropDown/GetResidentByFacilityId";

//Report Section
export const Uri_GetRptWoundAreaTrend = "Report/GetRptWoundAreaTrend";
export const Uri_GetWoundDocumentationByWoundID = "Report/GetWoundDocumentationByWoundID";
export const Uri_GetFacilityResidentWoundDocumentation = "Report/GetFacilityResidentWoundDocumentation";
export const Uri_GetFacilityReports = "Report/GetFacilityReports";
export const Uri_GetPintedAllAndSelectedReport = "Report/SavePintedAllAndSelectedReport";
export const Uri_GetQAPIReport = "Report/GetQAPIReport";



// end Report Section


export const Uri_GetCountry = "DropDown/GetCountry";


export const Uri_UpdateSystemSettings = "SystemSettings/update";

export const Uri_GetState = "DropDown/GetState";
//#endregion

//#region Users URL
export const Uri_AddUser = "User/create";
export const Uri_EditUser = "User/update";
export const Uri_SetPasswordDetalURL = "User/validateChangePasswordURL";
export const Uri_GetSystemSetting = "User/GetSystemSetting";
export const Uri_GetRegionalManagerAndVicePresident = "User/getRegionalManagerAndVicePresident";
export const Uri_GetMaintenanceNotice = "SystemSettings/GetMaintenanceNotice";


export const Uri_GetRolesByUserID = "RoleAction/GetRolesByUserID";
export const Uri_UpdateUserStatus = "User/UpdateUserStatus";
//#endregion

//#region  Brightry
export const Uri_CreateResident = "Resident/CreateResident";
export const Uri_CreateSalesOrder = "Resident/CreateSalesOrder";
export const Uri_CreateNotes = "Resident/CreateNotes";
export const Uri_UpsertResidentNotes = "Resident/UpsertResidentNotes";
export const Uri_GetAllResidentNotesByResidentID =
  "Resident/GetAllResidentNotesByResidentID";
export const Uri_CreateInsurance = "Resident/CreateInsurance";
export const Uri_CreatePhysician = "Resident/UpsertPhysician";

//#endregion

//#region Facility
export const Uri_AddFacility = "Facility/create";
export const Uri_SetBillingDate = "Facility/setBillingDate";
export const Uri_CreateFacilityGroup = "Facility/createFacilityGroup";
export const Uri_CreateFacilityMsg = "Facility/CreateFacilityMsg";
export const Uri_GetFacilityMsgsByFacilityId = "Facility/GetFacilityMsgsByFacilityId";

export const Uri_UpdateFacilityStatus = "Process/UpdateFacilityStatus";
export const Uri_UpdateSaleOrderStatus = "Process/UpdateSaleOrderStatus";
export const Uri_UpdateProcessQueue = "Process/UpdateProcessQueue";

export const Uri_AssignFacilityToBiller = "Process/AssignFacilityToBiller";
export const Uri_UpdateBillingFacilityStatus =
  "Process/UpdateBillingFacilityStatus";
  export const Uri_UnReleaseBillingFacility =
  "Process/UnReleaseBillingFacility";
export const Uri_UpdateBillingSaleOrderStatus =
  "Process/UpdateBillingSaleOrderStatus";

export const Uri_UpdateFacility = "Facility/update";

export const Uri_GetFacility = "Facility/GetAllFacility";
export const Uri_GetFacilityResidentWoundOrder = "Facility/GetFacilityResidentWoundOrder";
export const Uri_GetWorkqueueFacility = "Facility/GetWorkqueueFacility";
export const Uri_GetWorkqueueFacilityList = "Facility/GetWorkqueueFacilityList";

export const Uri_GetOstomyFacilityAndResidentByStatusId = "Facility/GetOstomyFacilityAndResidentByStatusId";
export const Uri_GetUrologicalFacilityAndResidentByStatusId = "Facility/GetUrologicalFacilityAndResidentByStatusId";
export const Uri_GetTracheostomyFacilityAndResidentByStatusId = "Facility/GetTracheostomyFacilityAndResidentByStatusId";


export const Uri_GetBillingFacility = "Facility/GetBillingFacility";
export const Uri_GetPartailOrderFacilities = "Facility/GetPartailOrderFacilities";



export const Uri_GetFacilityByID = "Facility/GetByID";
export const Uri_GetFacilityStatusById = "Process/GetFacilityStatusById";

export const Uri_facilityFetchByBrightreeID = "Facility/facilityFetchByBrightreeID";
export const Uri_fetchAllFacility = "Facility/fetchAllFacility";
export const Uri_parseFacilityFile = "Facility/parseFacilityFile";
export const Uri_saveParseFacilityList = "Facility/saveParseFacilityList";
export const Uri_saveParseUserList = "User/SaveUsersFile";

export const Uri_getClinicianFacilities = "Facility/GetClinicianFacilities";
export const Uri_upsertFacilityClinician = "Facility/UpsertFacilityClinician";
export const Uri_processJob = "Process/FastCareAPI";
export const Uri_getNightlySyncLog = "Process/GetNightlySyncLog";

export const URI_GetFacilitesReport = "Process/GetFacilitesReport";
export const URI_GetResidentHistoryReport = "Process/GetResidentHistoryReport";




export const Uri_getSyncLog = "Facility/getSyncLog";

export const Uri_getFacilitiesByOrganizationId = "Facility/GetAllFacilitiesByOrganizationId";
export const Uri_updateFacilitiesOwnershipGroup = "Facility/UpdateFacilityOwnershipGroup";
export const Uri_updateFacilityClinician = "Facility/UpdateFacilityClinician";
export const Uri_getFacilitiesByClinicianId = "Facility/GetFacilitiesByClinicianId";

//#endregion

//#region Facility Consultant
export const URI_AddConsultant = "FacilityConsultant/create";
export const URI_GetAllFacilityConsultantByFacilityID =
  "FacilityConsultant/GetAllFacilityConsultantByFacilityID";
//#endregion

//ICD10
export const URI_GetICDCode = "ICD/GetICDCode";
export const URI_GetAllICDCode = "ICD/GetAllICDCode";
export const URI_UpsertWoundToIcd = "ICD/UpsertWoundToIcd";

//#region Physician

export const URI_UpsertPhysician = "Physician/UpsertPhysician";
export const URI_GetAllPhysician = "Physician/GetAllPhysician";
export const URI_DeletePhysician = "Physician/DeletePhysician";
//#endregion

//#region  Resident
// export const Uri_CreateNotes = "Resident/CreateNotes";
export const URI_UpsertResident = "Resident/UpsertResident";
export const URI_UpsertInsurance = "Resident/UpsertInsurance";

export const URI_GetAllResidentByFacilityID =
  "Resident/GetAllResidentByFacilityID";
export const URI_GetAllWorkQueueResidentByFacilityID =
  "Resident/GetAllWorkQueueResidentByFacilityID";
export const URI_GetAllBillingQueueResidentByFacilityID =
  "Resident/GetAllBillingQueueResidentByFacilityID";

  export const URI_GetResidentHistory =
  "Resident/GetResidentHistory";

  export const URI_GetProcessResidentByFacilityId =
  "Resident/GetProcessResidentByFacilityId";

  export const URI_GetFacilityHistoryById =
  "Facility/GetFacilityHistoryById";

export const Uri_GetAllInsurance = "Resident/GetAllInsurance";

export const Uri_GetInsurances = "Resident/GetInsurance";
export const Uri_ResidentSearchBt = "Resident/ResidentSearchBt";
export const Uri_ResidentSearchFC = "Resident/ResidentSearchFC";

export const URI_UpsertResidentInsurance = "Resident/UpsertResidentInsurance";
export const URI_GetAllResidentInsurance =
  "Resident/GetResidentInsuranceByResidentID";
export const URI_GetResidentWoundByResidentID =
  "Wound/GetResidentWoundByResidentID";
  export const URI_GetLastWoundDetailByWoundId =
  "Wound/GetLastWoundDetailByWoundId";

  export const URI_GetResidentWoundByStatusID =
  "Wound/GetResidentWoundByStatusID";

  export const URI_GetResidentWoundHistory =
  "Wound/GetResidentWoundHistory";

export const URI_GetWoundHistoryByWoundID = "Wound/GetWoundHistoryByWoundID";

export const URI_UpsertResidentWound = "Wound/UpsertResidentWound";
//#endregion

//#region SalesOrder
export const URI_GetAllProducts = "SaleOrder/GetAllProducts";
export const URI_UpsertSaleOrder = "SaleOrder/UpsertSaleOrder";
export const URI_GetSalesOrderMsgsBySalesOrderId = "SaleOrder/GetSalesOrderMsgsBySalesOrderId";
export const URI_GetSkipResidentMsgsByResidentId = "resident/GetSkipResidentMsgsByResidentId";
export const URI_GetSkipWoundMsgsByResidentId = "wound/GetSkipWoundsMsgsByResidentId";

export const URI_GetWoundInfoChangeDetailByWoundId = "wound/GetWoundInfoChangeDetailByWoundId";




export const URI_CreateSalesOrderMsg = "SaleOrder/CreateSalesOrderMsg";

export const URI_GetSaleOrderByResidentWoundID =
  "SaleOrder/GetSaleOrderByResidentWoundID";
//#endregion


//#region  OUTs
export const URI_UpsertOstomySaleOrderInBrightreeSOAP = "OUTs/UpsertOstomySaleOrderInBrightreeSOAP";
export const URI_GetOstomySaleOrderByResidentId =
  "OUTs/GetOstomySaleOrderByResidentId";
  export const URI_ProcessOstomySaleOrderById =
  "OUTs/ProcessOstomySaleOrderById";
  export const URI_UpdateOstomyBillingOUtsOrderStatusByFacilityID =
  "OUTs/UpdateOstomyBillingOUtsOrderStatusByFacilityID";

//#endregion
export const URI_UpsertUrologicalSaleOrder = "OUTs/UpsertUrologicalSaleOrder";
export const URI_GetUrologicalSaleOrderByResidentId =
  "OUTs/GetUrologicalOrderByResidentID";
  export const URI_ProcessUrologicalSaleOrderById =
  "OUTs/ProcessUrologicalSaleOrderById";
  export const URI_UpdateUrologicalBillingOUtsOrderStatusByFacilityID =
  "OUTs/UpdateUrologicalBillingOUtsOderStatusByFacilityID";

  export const URI_UpsertTracheostomySaleOrder = "OUTs/UpsertTracheostomySaleOrder";
export const URI_GetTracheostomySaleOrderByResidentId =
  "OUTs/GetTracheostomySaleOrderByResidentId";
  export const URI_ProcessTracheostomySaleOrderById =
  "OUTs/ProcessTracheostomySaleOrderById";

  export const URI_UpdateTracheostomyBillingOUtsOrderStatusByFacilityID =
  "OUTs/UpdateTracheostomyBillingOUtsOrderStatusByFacilityID";
export const apiUrls = ApiUrls;

export const URI_GetSaleOrderByResident = "Report/PrintSalesOrderReport";