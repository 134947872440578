/*eslint-disable */
import { FormControlLabel, Input, MenuItem, Select } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import * as Api from "@/app/General/api";
import * as Common from "@/app/General/common";
import ProductSelection from "../../products/productselection";
import moment from "moment";
import WoundBedDescriptionInput from "../customComponents/WoundBedDescriptionInput";
import { injectIntl } from "react-intl";
import {UpsertResidentWound} from "@/app/pages/resident/newWoundAssessment/multiRegion/upsertResidentWoud";
import ClassComponentHelpers from "@/Utility/classes/ClassComponentHelper";
import {UPSERT_RESIDENT_WOUND_ASSESSMENT} from "@/Utility/consts/ids";

const customStyles = {
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      cursor: isDisabled ? "not-allowed" : "default",
      color: isDisabled ? "#aaa" : "white",
    };
  },
  option: (styles, { isDisabled }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? "red" : blue,
      color: "#FFF",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const pattern = ["##.#", "###"];
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      marginTop: "36px",
      marginLeft: "-10px",
      top: "455px",
      transitionDuration: "0s !important",
      transformOrigin: "none !important",
    },
  },
};

export class NewWoundAssessment extends ClassComponentHelpers {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      selectedWoundSkinAndUlcerTreatmentIds: [],
      formValidated: false,
      IsLoading: false,
      isLocationChange: false,
      woundTypeSelect: [],
      allWoundTypeSelect: [],
      selectedWoundTypeSelect: [],
      pressureLocation: [],
      nonPressureLocation: [],
      nonPressureLocationSurgery: [],
      thicknessStage: [],
      nonThicknessStage: [],
      WoundSide: [],
      WoundSevereTissue: [],
      WoundAcquisition: [],
      WoundExudateAmount: [],
      WoundExudateType: [],
      WoundDebridementType: [],
      woundBodyRegionSelect: [],
      woundICDCodeSheet: [],
      WoundPrimaryDressing: [],
      WoundSecondaryDressing: [],
      WoundBedDescription: [],
      WoundExudateColor: [],

      WoundEdges: [],
      SurroundingSkin: [],
      SkinAndUlcerTreatment: [],
      WoundDressingChangeFrequency: [],
      WoundType: "Pressure",
      WoundTypeSelected: "",
      IsThicknessStageTwo: false,
      IsPartialThickness: false,
      IsSloughRequired: false,
      IsNecroticRequired: false,
      ICD10Code: "",
      isReorderAndTissueTypeNotChange: props.isReorder ? true : false,
      ReoderAndIsDisableOrientation: false,
      Resident: JSON.parse(JSON.stringify(props.resident)),
      showProduct: false,
      orderNote: "",
      orientationLeft: null,
      orientationRight: null,

      orientationUpper: null,
      orientationLower: null,

      orientationInner: null,
      orientationOuter: null,

      orientationAnterior: null,
      orientationPosterior: null,

      orientationMedial: null,
      orientationLateral: null,

      orientationDorsal: null,
      orientationDistal: null,
      orientationProximal: null,
    };
    this.productSelection = null;
    this.intl = this.props.intl;
  }

  componentDidMount() {
    this.getAllLookupsValues();
    this.getAllWoundType();
    this.PushOperation(UpsertResidentWound,UPSERT_RESIDENT_WOUND_ASSESSMENT);
  }

  allowOneDigitAfterDecimal = (e) => {
    if (e.target.value.length > 0 && e.target.value.includes('.')) {
        if (e.target.value.toString().split(".")[1].length > 1) {
            return false
        } else if (e.target.value > 99.9) {
            return false
        }
    } else {
        if (e.target.value > 99.9) {
            return false
        }
    }

    if (e.target.value.length > 1 && e.target.value[0] === '0' && e.target.value[1] !== '.') {
        return false
    }

    Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
  }

  onWoundTypeChange = (e) => {
    if (e.target.value == 30) {
    } else {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "",
        "otherWoundType"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
    this.checkWoundType(e.target.value);
  };

  onExudateTypeChange = (e) => {
    if (e.target.value == 1192) {
    } else {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "",
        "otherExudateType"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
  };

  onBedDescriptionTypeChange = (e) => {
    if (e.target.value == 1049) {
    } else {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "",
        "otherWoundBedDesc"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
  };

  onExudateColorChange = (e) => {
    if (e.target.value == 1393) {
    } else {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "",
        "exudateColor"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
  };

  onSurroundingSkinTypeChange = (e) => {
    if (e.target.value == 1056) {
    } else {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "",
        "otherSurroundingSkin"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
  };

  getAllLookupsValues = async () => {
    const woundSevereTissueTypeLookup = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundSevereTissueType
    );
    this.setState({ WoundSevereTissue: woundSevereTissueTypeLookup });

    const woundAcquisitionLookup = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundAcquisition
    );
    this.setState({ WoundAcquisition: woundAcquisitionLookup });

    const woundExudateAmountLookup = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundExudateAmount
    );
    this.setState({ WoundExudateAmount: woundExudateAmountLookup });

    const woundExudateType = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundExudateType
    );
    this.setState({ WoundExudateType: woundExudateType });

    const woundDebridementType = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundDebridementType
    );
    this.setState({ WoundDebridementType: woundDebridementType });

    const surroundingSkin = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundSurroundingSkin
    );
    this.setState({ SurroundingSkin: surroundingSkin });

    const woundBedDescription = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundBedDescription
    );
    this.setState({ WoundBedDescription: woundBedDescription });

    const woundExudateColor = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundExudateColor
    );
    this.setState({ WoundExudateColor: woundExudateColor });

    const woundEdges = await Common.getLookupOptionsByHeaderName(
      Common.LookupsHeaderName.woundEdges
    );
    this.setState({ WoundEdges: woundEdges });

    const skinAndUlcerTreatment = await Common.getLookupOptionsObjectByHeaderName(
      Common.LookupsHeaderName.woundSkinAndUlcerTreatment
    );
    this.setState({ SkinAndUlcerTreatment: skinAndUlcerTreatment });

    const woundDressingChangeFrequency = await Common.getAllBTLookupOptions(
      Api.Uri_GetAllItemfrequencies
    );
    this.setState({
      WoundDressingChangeFrequency: woundDressingChangeFrequency,
    });
  };

  getAllWoundType = () => {
    Api.getRequest(Api.Uri_GetAllWoundType)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            let wountTypes = res.data.result.map((v) => (
              <option key={v.id} value={v.id}>
                {v.name}
              </option>
            ));
            this.setState({ allWoundTypeSelect: res.data.result });
            this.setState({ selectedWoundTypeSelect: wountTypes });
          }
        }
      })
      .catch((ex) => {});
    Api.getRequest(Api.Uri_GetAllICDDropDownList)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            let optionTemplate = res.data.result.woundType.map((v) => (
              <option key={v.key} value={v.key}>
                {v.value}
              </option>
            ));
            this.setState({ woundTypeSelect: optionTemplate });

            let optionBodyRegion = res.data.result.bodyRegion.map((v) => (
              <option key={v.key} value={v.key}>
                {v.value}
              </option>
            ));
            this.setState({ woundBodyRegionSelect: optionBodyRegion });
            this.setState({
              woundICDCodeSheet: res.data.result.woundToICDList,
            });

            var woundType = this.props.resident.wound.fkWoundTypeId;
            var bodyRegion = this.props.resident.wound.fkBodyRegionId;

            if (woundType > 0) {
              this.checkWoundType(woundType);
              this.onBodyRegionChangeSetValues(bodyRegion);
              if (
                this.state.Resident.wound.orientationLeftRight != null ||
                this.state.Resident.wound.orientationInnerOuter != null ||
                this.state.Resident.wound.orientationMedialLateral != null ||
                this.state.Resident.wound.orientationUpperLower != null ||
                this.state.Resident.wound.OrientationAnteriorPosterior !=
                  null ||
                this.state.Resident.wound.OrientationDorsalDistalProximal !=
                  null
              ) {
                this.setState({ ReoderAndIsDisableOrientation: true });
              }
            }
            this.setState({ IsLoading: true });
          }
        }
      })
      .catch((ex) => {});
  };

  onThicknessStageChange = (e) => {
    var valueToCompare = e.target.value;

    if (valueToCompare == 6) {
      valueToCompare = 1;
    }
    if (
      this.state.Resident.wound.thicknessIdForBackStagging <= valueToCompare ||
      this.state.Resident.wound.thicknessIdForBackStagging == null ||
      this.state.Resident.wound.thicknessIdForBackStagging == 6 ||
      (this.state.Resident.wound.thicknessIdForBackStagging == 5 &&
        valueToCompare != 2)
    ) {
      Common.updateInputValueInState(
        this.state.Resident.wound,
        e,
        this,
        this.state
      );

      if (e.target.value == "2") {
        var ICDCodeObj = {};
        ICDCodeObj.sloughPercentage = 0.0;
        ICDCodeObj.necroticPercentage = 0.0;

        this.setState({
          IsThicknessStageTwo: true,
          ICDCode: ICDCodeObj,
        });
      } else if (e.target.value == "5") {
        this.setState({ IsSloughRequired: true, IsNecroticRequired: true });
      } else {
        this.setState({ IsThicknessStageTwo: false });
      }
    } else {
      Common.showSuccessAlert(
        this.intl.formatMessage({
          id: "NEW_WOUND_ASSESSMENT.BACK_STAGGING_IS_NOT_ALLOW",
        }),
        "warning",
        "Warnng!",
        3000
      );
    }
  };

  onNonThicknessChange = (e) => {
    if (
      this.state.Resident.wound.thicknessIdForBackStagging <= e.target.value ||
      this.state.Resident.wound.thicknessIdForBackStagging == null
    ) {
      Common.updateInputValueInState(
        this.state.Resident.wound,
        e,
        this,
        this.state
      );

      if (e.target.value == "1246" || e.target.value == "1247") {
        this.setState({ IsNecroticRequired: true });
      } else {
        this.setState({ IsNecroticRequired: false });
      }
    } else {
      Common.showSuccessAlert(
        this.intl.formatMessage({
          id: "NEW_WOUND_ASSESSMENT.BACK_STAGGING_IS_NOT_ALLOW",
        }),
        "warning",
        "Warnng!",
        3000
      );
    }
    if (e.target.value == "1244") {
      var resident = this.state.Resident;
      resident.wound.fkDebridementTypeId = "1027";
      let newDate = "2021-02-12T00:00:00";

      resident.wound.debridementDate = newDate;
      this.setState({
        IsPartialThickness: true,
        Resident: resident,
      });
    } else {
      this.setState({
        IsPartialThickness: false,
      });
    }
  };

  selectWoundType = (selectedValue) => {
    let type = selectedValue.toLowerCase();

    let BodyRegionOption = [];
    let bodyRegion = this.state.woundBodyRegionSelect;
    switch (type) {
      case "pressure":
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          null,
          "fkWoundSevereTissueTypeId"
        );

        this.onTissueValueChangeSetDropdownValues(null, "Pressure");
        this.setState({ WoundTypeSelected: "Pressure" });

        break;
      case "other":
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          null,
          "fkWoundSevereTissueTypeId"
        );

        this.onTissueValueChangeSetDropdownValues(null, "other");
        this.setState({ WoundTypeSelected: "other" });

        break;
      case "nonpressure":
        BodyRegionOption = bodyRegion.map((v) => (
          <option key={v.key} value={v.key}>
            {v.value}
          </option>
        ));

        this.onTissueValueChangeSetDropdownValues(
          this.state.Resident.wound.fkWoundSevereTissueTypeId,
          "NonPressure"
        );
        this.setState({
          filterdWoundBodyRegionSelect: BodyRegionOption,
          WoundTypeSelected: "NonPressure",
        });
        break;
      case "surgical":
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          null,
          "fkWoundSevereTissueTypeId"
        );

        this.onTissueValueChangeSetDropdownValues(null, "Surgical");
        this.setState({ WoundTypeSelected: "Surgical" });

        break;
    }

    this.setState({
      selectedOption: selectedValue,
    });
  };

  onTissueValueChange = (event) => {
    if (
      this.state.Resident.wound.thicknessIdForBackStagging == 11 &&
      event.target.value == 1214 &&
      this.props.isReorder
    ) {
      Common.showSuccessAlert(
        this.intl.formatMessage({
          id: "NEW_WOUND_ASSESSMENT.BACK_STAGGING_IS_NOT_ALLOW",
        }),
        "warning",
        "Warnng!",
        3000
      );
      return false;
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      event,
      this,
      this.state
    );
    this.setState({
      IsThicknessStageTwo: false,
      IsPartialThickness: false,
    });

    this.onTissueValueChangeSetDropdownValues(
      event.target.value,
      this.state.WoundTypeSelected
    );
  };

  onTissueValueChangeSetDropdownValues = (tissueTypeId, woundType) => {
    if (woundType === "NonPressure") {
      var optionTemplate = [];
      optionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => tissueTypeId == menu.fkWoundSevereTissueTypeId)
        .map((v) => (
          <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
            {v.woundThicknessName}
          </option>
        ));
      const uniqueTags = [];
      optionTemplate.map((item) => {
        if (item.key != "null") {
          var findItem = uniqueTags.find((x) => x.key === item.key);
          if (!findItem) uniqueTags.push(item);
        }
      });

      this.setState({ thicknessStage: uniqueTags });

      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => tissueTypeId == menu.fkWoundSevereTissueTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));

      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });
    } else if (woundType === "Surgical") {
      var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
      var optionTemplate = [];
      optionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
            {v.woundThicknessName}
          </option>
        ));
      const uniqueTags = [];
      optionTemplate.map((item) => {
        var findItem = uniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) uniqueTags.push(item);
        }
      });

      this.setState({ thicknessStage: uniqueTags });

      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));

      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });
    } else {
      var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
      var optionTemplate = [];
      optionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
            {v.fkThicknessStageId == 2
              ? this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.STAGE_2" })
              : v.fkThicknessStageId == 3
              ? this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.STAGE_3" })
              : v.fkThicknessStageId == 4
              ? this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.STAGE_4" })
              : v.woundThicknessName}
          </option>
        ));
      const uniqueTags = [];

      optionTemplate.map((item) => {
        var findItem = uniqueTags.find((x) => x.key === item.key);
        if (item.key != "null" && item.key != 10 && item.key != 11) {
          if (!findItem) uniqueTags.push(item);
        }
      });

      this.setState({ thicknessStage: uniqueTags });

      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));

      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });
    }
  };

  onBodyRegionChange = (e) => {
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
    this.onBodyRegionChangeSetValues(e.target.value);

    this.showHideOrientation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  };

  onBodyRegionChangeSetValues = (bodyRegion) => {
    var optionTemplate = [];
    if (this.state.WoundTypeSelected == "NonPressure") {
      optionTemplate = this.state.woundICDCodeSheet
        .filter(
          (menu) =>
            bodyRegion == menu.fkBodyRegionId &&
            menu.fkThicknessStageId ==
              this.state.Resident.wound.fkWoundThicknessId
        )
        .map((v) => (
          <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
            {v.woundLocationName}
          </option>
        ));
    } else {
      var woundTypeId = 0;
      var woundThicknessStage = this.state.Resident.wound.fkWoundThicknessId;
      if (
        this.state.Resident.wound.fkWoundThicknessId == 1 ||
        this.state.Resident.wound.fkWoundThicknessId == 6
      ) {
        woundThicknessStage = 2;
      }
      if (this.state.WoundTypeSelected == "Pressure") {
        woundTypeId = Common.WoundType.pressure;
      } else {
        woundTypeId = this.state.Resident.wound.fkWoundTypeId;
      }

      optionTemplate = this.state.woundICDCodeSheet
        .filter(
          (menu) =>
            bodyRegion == menu.fkBodyRegionId &&
            menu.fkThicknessStageId == woundThicknessStage &&
            menu.fkWoundTypeId == woundTypeId
        )
        .map((v) => (
          <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
            {v.woundLocationName}
          </option>
        ));
    }
    const uniqueTags = [];
    optionTemplate.map((item) => {
      var findItem = uniqueTags.find((x) => x.key === item.key);
      if (!findItem) uniqueTags.push(item);
    });
    this.setState({ pressureLocation: uniqueTags });
  };

  onWoundLocationChange = (e) => {
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
    if (this.props.isReorder) {
      this.setOrientationValues(e.target.value, false);
      this.setState({ ReoderAndIsDisableOrientation: false });
    } else {
      this.setOrientationValues(e.target.value, true);
    }
  };

  setOrientationValues = (woundLocation, setOrientation) => {
    var icdCodes = this.state.woundICDCodeSheet.filter(
      (x) =>
        x.fkWoundSevereTissueTypeId ==
          this.state.Resident.wound.fkWoundSevereTissueTypeId &&
        x.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId &&
        x.fkBodyRegionId == this.state.Resident.wound.fkBodyRegionId &&
        x.fkWoundLocationId == woundLocation
    );
    this.showHideOrientation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    if (icdCodes) {
      for (var i = 0; i < icdCodes.length; i++) {
        let item = JSON.parse(JSON.stringify(icdCodes[i]));

        if (item.orientationLeftRight == Common.WoundOrientation.right) {
          this.setState({ orientationRight: item.orientationLeftRight });
        } else if (item.orientationLeftRight == Common.WoundOrientation.left) {
          this.setState({ orientationLeft: item.orientationLeftRight });
        }
        if (item.orientationUpperLower == Common.WoundOrientation.upper) {
          this.setState({ orientationUpper: item.orientationUpperLower });
        } else if (
          item.orientationUpperLower == Common.WoundOrientation.lower
        ) {
          this.setState({ orientationLower: item.orientationUpperLower });
        }
        if (item.orientationInnerOuter == Common.WoundOrientation.inner) {
          this.setState({ orientationInner: item.orientationInnerOuter });
        } else if (
          item.orientationInnerOuter == Common.WoundOrientation.outer
        ) {
          this.setState({ orientationOuter: item.orientationInnerOuter });
        }
        if (
          item.orientationAnteriorPosterior == Common.WoundOrientation.anterior
        ) {
          this.setState({
            orientationAnterior: item.orientationAnteriorPosterior,
          });
        } else if (
          item.orientationAnteriorPosterior == Common.WoundOrientation.posterior
        ) {
          this.setState({
            orientationPosterior: item.orientationAnteriorPosterior,
          });
        }
        if (item.orientationMedialLateral == Common.WoundOrientation.medial) {
          this.setState({ orientationMedial: item.orientationMedialLateral });
        } else if (
          item.orientationMedialLateral == Common.WoundOrientation.lateral
        ) {
          this.setState({ orientationLateral: item.orientationMedialLateral });
        }
        if (
          item.orientationDorsalDistalProximal == Common.WoundOrientation.dorsal
        ) {
          this.setState({
            orientationDorsal: item.orientationDorsalDistalProximal,
          });
        } else if (
          item.orientationDorsalDistalProximal == Common.WoundOrientation.distal
        ) {
          this.setState({
            orientationDistal: item.orientationDorsalDistalProximal,
          });
        } else if (
          item.orientationDorsalDistalProximal ==
          Common.WoundOrientation.proximal
        ) {
          this.setState({
            orientationProximal: item.orientationDorsalDistalProximal,
          });
        }
      }
    }

    if (setOrientation) {
      this.resetOrientation(this.state.Resident);
    }
  };

  resetOrientation(residentObj) {
    residentObj.wound.orientationLeftRight = null;
    residentObj.wound.orientationAnteriorPosterior = null;
    residentObj.wound.orientationDorsalDistalProximal = null;
    residentObj.wound.orientationInnerOuter = null;
    residentObj.wound.orientationMedialLateral = null;
    residentObj.wound.orientationUpperLower = null;
    residentObj.wound.orientationUpperLower = null;

    this.setState({ Resident: residentObj });
  }

  showHideOrientation = (
    orientationLeft,
    orientationRight,
    orientationUpper,
    orientationLower,
    orientationInner,
    orientationOuter,
    orientationAnterior,
    orientationPosterior,
    orientationMedial,
    orientationLateral,
    orientationDorsal,
    orientationDistal,
    orientationProximal
  ) => {
    this.setState({
      orientationLeft: orientationLeft,
      orientationRight: orientationRight,
      orientationUpper: orientationUpper,
      orientationLower: orientationLower,

      orientationInner: orientationInner,
      orientationOuter: orientationOuter,

      orientationAnterior: orientationAnterior,
      orientationPosterior: orientationPosterior,

      orientationMedial: orientationMedial,
      orientationLateral: orientationLateral,

      orientationDorsal: orientationDorsal,
      orientationDistal: orientationDistal,
      orientationProximal: orientationProximal,
    });
  };

  checkWoundType(value) {
    if (value == "5" || value == "23" || value == "24" || value == "25") {
      this.selectWoundType("pressure");
    } else if (value == "12" || value == "15") {
      this.selectWoundType("surgical");
    } else if (value == "30") {
      this.selectWoundType("other");
    } else {
      this.selectWoundType("nonpressure");
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      let obj = this.state.Resident;
      if (obj.wound.fkWoundThicknessId == "1243") {
        if (
          (obj.wound.sloughPercentage == "" ||
            obj.wound.sloughPercentage == 0) &&
          (obj.wound.necroticPercentage == "" ||
            obj.wound.necroticPercentage == 0)
        ) {
          Common.showSuccessAlert(
            this.intl.formatMessage({
              id: "NEW_WOUND_ASSESSMENT.UNSTAGEABLE_SLOUGH_NECROTIC",
            }),
            "warning",
            "Warning!",
            3000
          );
        } else {
            debugger;
            this.ExecuteOperation(
              this,
              UPSERT_RESIDENT_WOUND_ASSESSMENT
            );
          this.props.close();
        }
      } else {
        debugger;
        this.ExecuteOperation(
          this,
          UPSERT_RESIDENT_WOUND_ASSESSMENT
        );
      }
    }
  };

  setProductSelectionDetailToSend(result, woundDetail) {
    let selectedProducts = null;
    if (this.productSelection != null) {
      selectedProducts = this.productSelection.getSelectedProductList();
    }

    let proudctObjToSend = {
      saleOrderId:
        selectedProducts != null
          ? selectedProducts.salesOrder.saleOrderId
          : null,
      fkResidentWoundId: result,
      FkDressingChangeFrequencyId:
        selectedProducts != null
          ? selectedProducts.salesOrder.fkDressingChangeFrequencyId
          : null,
      OtherAdditionalSecondaryDressing:
        selectedProducts != null
          ? selectedProducts.salesOrder.otherAdditionalSecondaryDressing
          : null,
      OtherAdditionalPrimaryDressing:
        selectedProducts != null
          ? selectedProducts.salesOrder.otherAdditionalPrimaryDressing
          : null,
      OrderNote:
        selectedProducts != null ? selectedProducts.salesOrder.orderNote : null,
      saleOrderViewModels: [],
      otherPrimaryProducts:
        selectedProducts != null ? selectedProducts.otherPrimaryProducts : null,
      otherSecodaryProducts:
        selectedProducts != null
          ? selectedProducts.selectedSecondaryProducts
          : null,
      otherFillerProducts:
        selectedProducts != null
          ? selectedProducts.selectedOtherFillerProducts
          : null,
      otherTraditionalProducts:
        selectedProducts != null
          ? selectedProducts.selectedOtherTraditionalProducts
          : null,
      isReorder: this.props.isReorder,
      isAssessmentRequired: true,
      isNoOrder:
        woundDetail.wound.fkWoundThicknessId == 1 ||
        woundDetail.wound.fkWoundThicknessId == 6
          ? true
          : false,
    };
    if (selectedProducts != null) {
      selectedProducts.products.forEach((value) => {
        let obj = {
          fkProductId: value.id,
          saleOrderProductId: value.saleOrderProductId,
          quantity: value.quantity,
          remainingQuantity: value.remainingQuantity,
          productLevel: value.productLevel,
        };
        proudctObjToSend.saleOrderViewModels.push(obj);
      });
    }

    this.UpsertSaleOrderApiCall(proudctObjToSend);
  }

  UpsertSaleOrderApiCall(productObject) {
    let obj = {
      ...productObject,
      createdInQueueId: this.props.queue || Common.ProcessStatus.workQueueID,
    };

    Api.postRequest(Api.URI_UpsertSaleOrder, obj)
      .then((res) => {
        if (res.data.success) {
          if (res.data.result != null) {
            Common.showSuccessAlertAutoClose(
              this.intl.formatMessage({
                id: "NEW_WOUND_ASSESSMENT.SALES_ORDER_CREATED",
              }),
              "success",
              this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.SUCCESS" }),
              3000
            );
            this.props.close();
          } else {
            Common.showSuccessAlert(
              res.data.message,
              "warning",
              this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.FAILED" }),
              3000
            );
          }
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.FAILED" }),
            3000
          );
        }
      })
      .catch((ex) => {
        Common.showSuccessAlertAutoClose(
          this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.ERROR_OCCURED" }),
          "warning",
          this.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.FAILED" }),
          3000
        );
      });
  }

  handleUtdChange = (event) => {
    if (event.target.checked) {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "0.00",
        "woundDepth"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      event,
      this,
      this.state
    );
  };

  handleCheckboxChange = (e, checkboxId) => {
    const isChecked = e.target.checked;
    const percentage =
      this.state.Resident?.wound?.woundBedDescriptions?.[
        `${checkboxId}_percentage`
      ] || "";

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.Resident.wound.woundBedDescriptions = {
        ...newState.Resident.wound.woundBedDescriptions,
      };

      if (isChecked) {
        newState.Resident.wound.woundBedDescriptions[checkboxId] = {
          Fk_WoundBedDescriptionID: checkboxId,
          Percentage: percentage,
        };
      } else {
        delete newState.Resident.wound.woundBedDescriptions[checkboxId];
      }

      return newState;
    });
  };

  handleOtherPercentage = (e) => {
    const inputValue = e.target.value;
    const Id = e.target.id;

    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.Resident.wound[Id] = inputValue;
      return newState;
    });
  };

  handlePercentageChange = (e) => {
    const inputValue = e.target.value;
    const percentage = inputValue !== "" ? parseInt(inputValue) : 0;
    const Id = e.target.id;

    const otherInputs = [
      "granulationPerc",
      "hyperGranulationPerc",
      "sloughPerc",
      "necroticPerc",
      "epithelialPerc",
      "musclePerc",
      "otherPerc",
    ];

    this.setState((prevState) => {
      const newState = { ...prevState };

      if (percentage >= 0 && percentage <= 100) {
        newState.Resident.wound[Id] = percentage;

        const atLeastOneInput = otherInputs.some((inputId) => {
          const inputPercentage = newState.Resident.wound[inputId] || 0;
          return inputPercentage > 0;
        });

        const combinedPercentage = otherInputs.reduce((sum, inputId) => {
          return sum + (newState.Resident.wound[inputId] || 0);
        }, 0);

        if (atLeastOneInput && combinedPercentage <= 100) {
          newState.hasValue = true;
          this.setState({ errorMessage: "" });

          return newState;
        } else {
          newState.hasValue = false;
          this.setState({
            errorMessage: this.intl.formatMessage({
              id: "NEW_WOUND_ASSESSMENT.COMBINED_PERCENTAGE",
            }),
          });
          newState.Resident.wound[Id] = 0;
          return newState;
        }
      } else {
        newState.hasValue = false;

        this.setState({
          errorMessage: this.intl.formatMessage({
            id: "NEW_WOUND_ASSESSMENT.PERCENTAGE_BETWEEN",
          }),
        });
      }

      return prevState;
    });
  };

  renderWoundForm() {
    return (
      <>
        {this.state.IsLoading == true ? (
          <div>
            {!this.props.canView ? (
              <div className="d-flex justify-content-end py-3">
                <Button
                  type="button"
                  variant="dark"
                  size="sm"
                  onClick={() => this.props.close()}
                >
                  {this.intl.formatMessage({
                    id: "NEW_WOUND_ASSESSMENT.VIEW_HISTORY",
                  })}
                </Button>
              </div>
            ) : null}
            <fieldset disabled={!this.props.canView ? true : false}>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-8 col-xl-8">
                  <div className="form-group">
                    <label className="form-label" htmlFor="ResidentName">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.RESIDENT_NAME",
                      })}
                    </label>
                    <input
                      type="text"
                      id="ResidentName"
                      name="residentName"
                      disabled
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.residentName
                      )}
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.RESIDENT_NAME_REQUIRED",
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="EvaluationDate">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.EVALUATION_DATE",
                      })}
                    </label>
                    <input
                      type="date"
                      id="EvaluationDate"
                      name="evaluationDate"
                      required
                      disabled={this.props.isEdit}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.getInputStringDate(
                        this.state.Resident.wound.evaluationDate
                          ? new Date(this.state.Resident.wound.evaluationDate)
                          : new Date()
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <div className="form-group">
                    <label className="form-label" htmlFor="FacilityName">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.FACILITY_NAME",
                      })}
                    </label>
                    <input
                      type="text"
                      id="FacilityName"
                      name="facilityName"
                      disabled
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.facilityName
                      )}
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.FACILITY_NAME_REQUIRED",
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="FirstName">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.ROOM_UNIT",
                      })}
                    </label>
                    <input
                      type="text"
                      id="RoomUnit"
                      name="roomUnit"
                      disabled
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.roomUnit
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="PhysicanName">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.PHYSICIAN",
                      })}
                    </label>
                    <input
                      type="text"
                      id="physicianName"
                      disabled
                      name="physicianName"
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.physicanName
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.PHYSICIAN_NAME",
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="EvaluatorName">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.EVALUATOR_NAME",
                      })}
                    </label>
                    <input
                      type="text"
                      id="evaluatedBy"
                      name="evaluatedBy"
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.evaluatedBy
                      )}
                      disabled
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="woundType">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.WOUND_TYPE",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="woundType"
                      name="fkWoundTypeId"
                      disabled={
                        (this.props.isReorder == true ? true : false) ||
                        (this.props.isEdit || (this.state.Resident.wound.id > 0 && this.state.Resident.wound.fkAcquisitionId))
                      }
                      required
                      onChange={(e) => this.onWoundTypeChange(e)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundTypeId
                      )}
                    >
                      <option></option>
                      {this.state.selectedWoundTypeSelect}
                    </select>
                  </div>
                  {this.state.Resident.wound.fkWoundTypeId == 30 && (
                    <>
                      <div className="form-group">
                        <label className="form-label" htmlFor="Length">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.OTHER_WOUND_TYPE",
                          })}
                        </label>
                        <input
                          type="text"
                          id="otherWoundType"
                          name="otherWoundType"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.otherWoundType
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </>
                  )}
                </div>
                {this.state.WoundTypeSelected == "NonPressure" && (
                  <>
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="IssueType">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.MOST_SEVERE_TISSUE_TYPE",
                          })}{" "}
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="IssueType"
                          name="fkWoundSevereTissueTypeId"
                          required
                          disabled={this.props.isEdit}
                          onChange={this.onTissueValueChange}
                          value={Common.replaceNullWithString(
                            this.state.Resident.wound.fkWoundSevereTissueTypeId
                          )}
                        >
                          <option></option>
                          {this.state.WoundSevereTissue}
                        </select>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="thicknessStage">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.THICKNESS_STAGE",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkWoundThicknessId"
                      name="fkWoundThicknessId"
                      onChange={(e) => this.onThicknessStageChange(e)}
                      disabled={this.props.isEdit}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundThicknessId
                      )}
                    >
                      <option></option>
                      {this.state.WoundTypeSelected == "Pressure" && (
                        <option value="1">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.STAGE_1",
                          })}
                        </option>
                      )}
                      {this.state.thicknessStage}
                      {this.state.WoundTypeSelected == "Pressure" && (
                        <option value="6">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.DTI",
                          })}
                        </option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="Country"
                    >
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.BODY_REGION",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="fkBodyRegionId"
                      required
                      onChange={(e) => this.onBodyRegionChange(e)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkBodyRegionId
                      )}
                      id="fkBodyRegionId"
                      disabled={
                        (this.props.isEdit || (this.state.Resident.wound.id > 0 && this.state.Resident.wound.fkAcquisitionId)) ||
                        this.state.isReorderAndTissueTypeNotChange
                      }
                    >
                      <option></option>
                      {this.state.woundBodyRegionSelect}
                      <option value="10011">
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.OTHER",
                        })}
                      </option>
                    </select>
                  </div>
                  {this.state.Resident.wound.fkWoundLocationId == 10011 && (
                    <>
                      <div className="form-group">
                        <label className="form-label" htmlFor="EvaluatorName">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.OTHER_BODY_REGION",
                          })}
                        </label>
                        <input
                          type="text"
                          id="otherBodyRegion"
                          name="otherBodyRegion"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) => {
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            ),
                              this.setState({ isLocationChange: true });
                          }}
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.otherBodyRegion
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="heel"
                    >
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.LOCATION",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fk_PressureLocationID"
                      name="fkWoundLocationId"
                      required
                      onChange={(e) => this.onWoundLocationChange(e)}
                      disabled={this.props.isEdit}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundLocationId
                      )}
                    >
                      <option></option>
                      {this.state.pressureLocation}
                      <option value="10012">
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.OTHER",
                        })}
                      </option>
                    </select>
                  </div>
                  {this.state.Resident.wound.fkWoundLocationId == 10012 && (
                    <>
                      <div className="form-group">
                        <label className="form-label" htmlFor="EvaluatorName">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.OTHER_LOCATION",
                          })}
                        </label>
                        <input
                          type="text"
                          id="woundlocation"
                          name="woundLocation"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) => {
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            ),
                              this.setState({ isLocationChange: true });
                          }}
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.woundLocation
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </>
                  )}
                  {this.state.isLocationChange && !this.props.canView && (
                    <>
                      <div className="form-group">
                        <label className="form-label" htmlFor="EvaluatorName">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.CHANGE_LOCATION_REASON",
                          })}
                        </label>
                        <input
                          type="text"
                          id="woundlocation"
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.woundLocation
                          )}
                          className="form-control form-control-sm"
                          disabled={this.props.isEdit}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Status">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.ACQUISITION",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="Acquisition"
                      name="fkAcquisitionId"
                      disabled={
                        this.props.isEdit ||
                        (this.state.Resident.wound.id > 0 &&
                          this.state.Resident.wound.fkAcquisitionId)
                      }
                      required
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkAcquisitionId
                      )}
                    >
                      <option></option>
                      {this.state.WoundAcquisition}
                    </select>
                  </div>
                </div>

                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <label className="form-label font-weight-bold">
                    {this.intl.formatMessage({
                      id: "NEW_WOUND_ASSESSMENT.ORIENTATION",
                    })}
                  </label>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationLeftRight"
                            id="inlineRadio1"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.right}
                            required={
                              this.state.orientationLeft ==
                                Common.WoundOrientation.right ||
                              (this.state.orientationRight ==
                                Common.WoundOrientation.right &&
                                true)
                            }
                            checked={
                              this.state.Resident.wound.orientationLeftRight ==
                              Common.WoundOrientation.right
                                ? true
                                : false
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationRight ==
                              Common.WoundOrientation.right
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="inlineRadio1"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_RIGHT",
                              })}
                            </small>
                            {this.state.orientationRight ==
                              Common.WoundOrientation.right && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationLeftRight"
                            id="inlineRadio2"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound.orientationLeftRight ==
                              Common.WoundOrientation.left
                                ? true
                                : false
                            }
                            value={Common.WoundOrientation.left}
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationLeft ==
                              Common.WoundOrientation.left
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="inlineRadio2"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_LEFT",
                              })}
                            </small>
                            {this.state.orientationLeft ==
                              Common.WoundOrientation.left && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationUpperLower"
                            id="Upper"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound.orientationUpperLower ==
                              Common.WoundOrientation.upper
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationUpper ==
                                Common.WoundOrientation.upper ||
                              (this.state.orientationLower ==
                                Common.WoundOrientation.lower &&
                                true)
                            }
                            value={Common.WoundOrientation.upper}
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationUpper ==
                              Common.WoundOrientation.upper
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Upper"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_UPPER",
                              })}
                            </small>
                            {this.state.orientationUpper ==
                              Common.WoundOrientation.upper && "*"}
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationUpperLower"
                            id="Lower"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound.orientationUpperLower ==
                              Common.WoundOrientation.lower
                                ? true
                                : false
                            }
                            value={Common.WoundOrientation.lower}
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationUpperLower ==
                              Common.WoundOrientation.lower
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Lower"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_LOWER",
                              })}
                            </small>
                            {this.state.orientationLower ==
                              Common.WoundOrientation.lower && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationInnerOuter"
                            id="Inner"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.inner}
                            checked={
                              this.state.Resident.wound.orientationInnerOuter ==
                              Common.WoundOrientation.inner
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationInner ==
                                Common.WoundOrientation.inner ||
                              (this.state.orientationOuter ==
                                Common.WoundOrientation.outer &&
                                true)
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationInner ==
                              Common.WoundOrientation.inner
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Inner"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_INNER",
                              })}
                            </small>
                            {this.state.orientationInner ==
                              Common.WoundOrientation.inner && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationInnerOuter"
                            id="Outer"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.outer}
                            checked={
                              this.state.Resident.wound.orientationInnerOuter ==
                              Common.WoundOrientation.outer
                                ? true
                                : false
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationOuter ==
                              Common.WoundOrientation.outer
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Outer"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_OUTER",
                              })}
                            </small>
                            {this.state.orientationOuter ==
                              Common.WoundOrientation.outer && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationAnteriorPosterior"
                            id="Anterior"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.anterior}
                            required={
                              this.state.orientationAnterior ==
                                Common.WoundOrientation.anterior ||
                              (this.state.orientationPosterior ==
                                Common.WoundOrientation.posterior &&
                                true)
                            }
                            checked={
                              this.state.Resident.wound
                                .orientationAnteriorPosterior ==
                              Common.WoundOrientation.anterior
                                ? true
                                : false
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationAnterior ==
                              Common.WoundOrientation.anterior
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Anterior"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_ANTERIOR",
                              })}
                            </small>
                            {this.state.orientationAnterior ==
                              Common.WoundOrientation.anterior && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationAnteriorPosterior"
                            id="Posterior"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound
                                .orientationAnteriorPosterior ==
                              Common.WoundOrientation.posterior
                                ? true
                                : false
                            }
                            value={Common.WoundOrientation.posterior}
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationPosterior ==
                              Common.WoundOrientation.posterior
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Posterior"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id:
                                  "NEW_WOUND_ASSESSMENT.ORIENTATION_POSTERIOR",
                              })}
                            </small>
                            {this.state.orientationPosterior ==
                              Common.WoundOrientation.posterior && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationMedialLateral"
                            id="Medial"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.medial}
                            checked={
                              this.state.Resident.wound
                                .orientationMedialLateral ==
                              Common.WoundOrientation.medial
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationMedial ==
                                Common.WoundOrientation.medial ||
                              (this.state.orientationLateral ==
                                Common.WoundOrientation.lateral &&
                                true)
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationMedial ==
                              Common.WoundOrientation.medial
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Medial"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_MEDIAL",
                              })}
                            </small>
                            {this.state.orientationMedial ==
                              Common.WoundOrientation.medial && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationMedialLateral"
                            id="Lateral"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.lateral}
                            checked={
                              this.state.Resident.wound
                                .orientationMedialLateral ==
                              Common.WoundOrientation.lateral
                                ? true
                                : false
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationLateral ==
                              Common.WoundOrientation.lateral
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Lateral"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_LATERAL",
                              })}
                            </small>
                            {this.state.orientationLateral ==
                              Common.WoundOrientation.lateral && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationDorsalDistalProximal"
                            id="Dorsal"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.dorsal}
                            checked={
                              this.state.Resident.wound
                                .orientationDorsalDistalProximal ==
                              Common.WoundOrientation.dorsal
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationDorsal ==
                                Common.WoundOrientation.dorsal ||
                              this.state.orientationDistal ==
                                Common.WoundOrientation.distal ||
                              (this.state.orientationProximal ==
                                Common.WoundOrientation.proximal &&
                                true)
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationDorsal ==
                              Common.WoundOrientation.dorsal
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Dorsal"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_DORSAL",
                              })}
                            </small>
                            {this.state.orientationDorsal ==
                              Common.WoundOrientation.dorsal && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationDorsalDistalProximal"
                            id="Distal"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.distal}
                            checked={
                              this.state.Resident.wound
                                .orientationDorsalDistalProximal ==
                              Common.WoundOrientation.distal
                                ? true
                                : false
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationDistal ==
                              Common.WoundOrientation.distal
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Distal"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_DISTAL",
                              })}
                            </small>
                            {this.state.orientationDistal ==
                              Common.WoundOrientation.distal && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationDorsalDistalProximal"
                            id="Proximal"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.proximal}
                            checked={
                              this.state.Resident.wound
                                .orientationDorsalDistalProximal ==
                              Common.WoundOrientation.proximal
                                ? true
                                : false
                            }
                            disabled={
                              this.props.isEdit ||
                              (this.state.isReorderAndTissueTypeNotChange &&
                                this.state.ReoderAndIsDisableOrientation)
                            }
                          />
                          <label
                            className={
                              this.state.orientationProximal ==
                              Common.WoundOrientation.proximal
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Proximal"
                          >
                            <small>
                              {this.intl.formatMessage({
                                id: "NEW_WOUND_ASSESSMENT.ORIENTATION_PROXIMAL",
                              })}
                            </small>
                            {this.state.orientationProximal ==
                              Common.WoundOrientation.proximal && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    {(!this.state.isReorderAndTissueTypeNotChange ||
                      !this.state.ReoderAndIsDisableOrientation) && (
                      <button
                        type="button"
                        disabled={this.props.isEdit}
                        onClick={(e) =>
                          this.resetOrientation(this.state.Resident)
                        }
                        className="btn btn-danger btn-xs"
                      >
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.RESET_ORIENTATION",
                        })}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Length">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.LENGTH_CM",
                      })}
                    </label>
                    <input
                      type="number"
                      id="Length"
                      name="woundLength"
                      required
                      pattern={pattern}
                      placeholder="12.3"
                      min="0.1"
                      max="99.9"
                      step="0.1"
                      maxLength="5"
                      disabled={this.props.isEdit}
                      onChange={this.allowOneDigitAfterDecimal}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.woundLength
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Width">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.WIDTH_CM",
                      })}
                    </label>
                    <input
                      type="number"
                      id="Width"
                      name="woundWidth"
                      required
                      pattern={pattern}
                      placeholder="12.3"
                      min="0.1"
                      max="99.9"
                      step="0.1"
                      maxLength="5"
                      disabled={this.props.isEdit}
                      onChange={this.allowOneDigitAfterDecimal}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.woundWidth
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Depth">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.DEPTH_CM",
                      })}
                    </label>
                    <input
                      type="number"
                      id="Depth"
                      name="woundDepth"
                      required
                      pattern={pattern}
                      placeholder="12.3"
                      min="0.1"
                      max="99.9"
                      step="0.1"
                      maxLength="5"
                      disabled={
                        this.props.isEdit || this.state.Resident.wound.isUtd
                      }
                      onChange={this.allowOneDigitAfterDecimal}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.woundDepth
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="isUtd"
                          checked={this.state.Resident.wound.isUtd}
                          onChange={(e) => this.handleUtdChange(e)}
                          value="gilad"
                        />
                      }
                      label={this.intl.formatMessage({ id: "UTD" })}
                      disabled={this.props.isEdit}
                      className="font-weight-bold"
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="EvaluationDate">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.DATE_FIRST_OBSERVED",
                      })}
                    </label>
                    <input
                      type="date"
                      id="dateFirstObserved"
                      name="dateFirstObserved"
                      required
                      disabled={
                        this.props.isEdit ||
                        this.state.Resident.wound.woundDetailId > 0
                      }
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.getInputStringDate(
                        new Date(this.state.Resident.wound.dateFirstObserved)
                      )}
                      max={Common.getCurrentDate("-")}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="tunneling"
                          disabled={this.props.isEdit}
                          checked={this.state.Resident.wound.tunneling}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value="gilad"
                        />
                      }
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.TUNNELING",
                      })}
                    />
                  </div>
                </div>

                {this.state.Resident.wound.tunneling && (
                  <>
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="tunnelingDepth">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.T_DEPTH_CM",
                          })}
                        </label>
                        <input
                          type="text"
                          id="tunnelingDepth"
                          name="tunnelingDepth"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.tunnelingDepth
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.T_CLOCK_POSITION",
                          })}
                        </label>
                        <input
                          type="number"
                          id="TunnelingClockPosition"
                          name="tunnelingClockPosition"
                          required
                          disabled={this.props.isEdit}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.tunnelingClockPosition
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="undermining"
                          disabled={this.props.isEdit}
                          checked={this.state.Resident.wound.undermining}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value="gilad"
                        />
                      }
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.UNDERMINING",
                      })}
                    />
                  </div>
                </div>
                {this.state.Resident.wound.undermining && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="underminingDepth"
                        >
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.U_DEPTH_CM",
                          })}
                        </label>
                        <input
                          type="text"
                          id="underminingDepth"
                          name="underminingDepth"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.underminingDepth
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.FROM_U_CLOCK_POSITION",
                          })}
                        </label>
                        <input
                          type="number"
                          id="UnderminingClockPosition"
                          name="underminingClockPosition"
                          required
                          disabled={this.props.isEdit}
                          min="1"
                          max="12"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.underminingClockPosition
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.TO_U_CLOCK_POSITION",
                          })}
                        </label>
                        <input
                          type="number"
                          id="toUnderminingClockPosition"
                          name="toUnderminingClockPosition"
                          required
                          disabled={this.props.isEdit}
                          min="1"
                          max="12"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.toUnderminingClockPosition
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fkExudateAmountId">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.EXUDATE_AMOUNT",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkExudateAmountId"
                      name="fkExudateAmountId"
                      required
                      disabled={this.props.isEdit}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkExudateAmountId
                      )}
                    >
                      <option value=""></option>
                      <option value="2">
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.NONE",
                        })}
                      </option>
                      <option value="3">
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.LIGHT",
                        })}
                      </option>
                      <option value="4">
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.MODERATE",
                        })}
                      </option>
                      <option value="5">
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.HEAVY",
                        })}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="ExudateType">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.EXUDATE_TYPE",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="ExudateType"
                      name="fkExudateTypeId"
                      disabled={this.props.isEdit}
                      onChange={(e) => this.onExudateTypeChange(e)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkExudateTypeId
                      )}
                    >
                      <option></option>
                      {this.state.WoundExudateType}
                    </select>
                  </div>
                </div>
                {this.state.Resident.wound.fkExudateTypeId == 1192 && (
                  <>
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Length">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.OTHER_EXUDATE_TYPE",
                          })}
                        </label>
                        <input
                          type="text"
                          id="OtherExudateType"
                          name="otherExudateType"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.otherExudateType
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="ExudateType">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.EXUDATE_COLOR",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkWoundColorId"
                      name="fkWoundColorId"
                      disabled={this.props.isEdit}
                      onChange={(e) => this.onExudateColorChange(e)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundColorId
                      )}
                    >
                      <option></option>
                      {this.state.WoundExudateColor}
                    </select>
                  </div>
                </div>
                {this.state.Resident.wound.fkExudateTypeId == 1192 && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="ExudateColor">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.EXUDATE_COLOR_OTHER",
                          })}
                        </label>
                        <input
                          type="text"
                          id="ExudateColor"
                          name="exudateColor"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.exudateColor
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="odor"
                          disabled={this.props.isEdit}
                          checked={this.state.Resident.wound.odor}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value="gilad"
                        />
                      }
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.ODOR",
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="pain"
                          disabled={this.props.isEdit}
                          checked={this.state.Resident.wound.pain}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value="gilad"
                        />
                      }
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.PAIN",
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="DebridementDate">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.DEBRIDEMENT_DATE",
                      })}
                    </label>
                    <input
                      type="Date"
                      id="DebridementDate"
                      name="debridementDate"
                      max={Common.getInputStringDateUsingMoment(moment())}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={
                        this.state.Resident.wound.debridementDate == "" ||
                        this.state.Resident.wound.debridementDate == null
                          ? this.state.Resident.wound.debridementDate
                          : Common.getInputStringDate(
                              new Date(
                                this.state.Resident.wound.debridementDate
                              )
                            )
                      }
                      className="form-control form-control-sm"
                      disabled={this.props.isEdit}
                    />
                  </div>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fkDebridementTypeId">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.DEBRIDEMENT_TYPE",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkDebridementTypeId"
                      name="fkDebridementTypeId"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkDebridementTypeId
                      )}
                      disabled={this.props.isEdit}
                    >
                      <option value=""></option>
                      {this.state.WoundDebridementType}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                  <label className="form-label" htmlFor="fkWoundBedDescription">
                    {this.intl.formatMessage({
                      id: "NEW_WOUND_ASSESSMENT.WOUND_BED_DESCRIPTION",
                    })}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="form-group">
                    <WoundBedDescriptionInput
                      id={"granulationPerc"}
                      disabled={this.props.isEdit}
                      value={this.state.Resident?.wound?.granulationPerc}
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.GRANULATION",
                      })}
                      handlePercentageChange={(e) => {
                        this.handlePercentageChange(e);
                      }}
                      hasValue={this.state.Resident?.wound?.granulationPerc > 0}
                    />
                    <WoundBedDescriptionInput
                      id={"hyperGranulationPerc"}
                      disabled={this.props.isEdit}
                      value={this.state.Resident?.wound?.hyperGranulationPerc}
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.HYPERGRANULATION",
                      })}
                      handlePercentageChange={(e) => {
                        this.handlePercentageChange(e);
                      }}
                      hasValue={
                        this.state.Resident?.wound?.hyperGranulationPerc > 0
                      }
                    />
                    <WoundBedDescriptionInput
                      id={"sloughPerc"}
                      disabled={this.props.isEdit}
                      value={this.state.Resident?.wound?.sloughPerc}
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.SLOUGH",
                      })}
                      handlePercentageChange={(e) => {
                        this.handlePercentageChange(e);
                      }}
                      hasValue={this.state.Resident?.wound?.sloughPerc > 0}
                    />
                    <WoundBedDescriptionInput
                      id={"necroticPerc"}
                      disabled={this.props.isEdit}
                      value={this.state.Resident?.wound?.necroticPerc}
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.NECROTIC_ESCHAR",
                      })}
                      handlePercentageChange={(e) => {
                        this.handlePercentageChange(e);
                      }}
                      hasValue={this.state.Resident?.wound?.necroticPerc > 0}
                    />
                    <WoundBedDescriptionInput
                      id={"epithelialPerc"}
                      disabled={this.props.isEdit}
                      value={this.state.Resident?.wound?.epithelialPerc}
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.EPITHELIAL",
                      })}
                      handlePercentageChange={(e) => {
                        this.handlePercentageChange(e);
                      }}
                      hasValue={this.state.Resident?.wound?.epithelialPerc > 0}
                    />
                    <WoundBedDescriptionInput
                      id={"musclePerc"}
                      disabled={this.props.isEdit}
                      value={this.state.Resident?.wound?.musclePerc}
                      label={this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.MUSCLE_TENDON_BONE",
                      })}
                      handlePercentageChange={(e) => {
                        this.handlePercentageChange(e);
                      }}
                      hasValue={this.state.Resident?.wound?.musclePerc > 0}
                    />

                    <div className="form-check form-check-inline">
                      <label className="form-check-label" htmlFor={"OtherPerc"}>
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.OTHER",
                        })}
                      </label>
                      {this.state.Resident?.wound?.otherPerc > 0 && (
                        <textarea
                          className="form-control ml-2"
                          style={{ height: "40px" }}
                          name="otherWoundDesc"
                          disabled={this.props.isEdit}
                          required={this.state.Resident?.wound?.otherPerc > 0}
                          id="otherWoundDesc"
                          value={
                            this.state.Resident?.wound?.otherWoundDesc || ""
                          }
                          onChange={(e) => this.handleOtherPercentage(e)}
                          cols={10}
                        ></textarea>
                      )}
                      <input
                        id="otherPerc"
                        type="text"
                        min={0}
                        max={100}
                        className="form-control form-control-sm ml-3 w-60px hide-arrows"
                        value={this.state.Resident?.wound?.otherPerc || ""}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => this.handlePercentageChange(e)}
                        required={!this.state.hasValue}
                        disabled={this.props.isEdit}
                      />
                      <span className="ml-1">%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="fkWoundSurroundingSkin"
                    >
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.SURROUNDING_SKIN",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkWoundSurroundingSkin"
                      name="fkWoundSurroundingSkinId"
                      disabled={this.props.isEdit}
                      onChange={(e) => this.onSurroundingSkinTypeChange(e)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundSurroundingSkinId
                      )}
                    >
                      <option value=""></option>
                      {this.state.SurroundingSkin}
                    </select>
                  </div>
                </div>
                {this.state.Resident.wound.fkWoundSurroundingSkinId == 1056 && (
                  <>
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Length">
                          {this.intl.formatMessage({
                            id: "NEW_WOUND_ASSESSMENT.OTHER_SURROUNDING_SKIN",
                          })}
                        </label>
                        <input
                          type="text"
                          id="otherSurroundingSkin"
                          name="otherSurroundingSkin"
                          required
                          disabled={this.props.isEdit}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.otherSurroundingSkin
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fkWoundEdgesId">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.WOUND_EDGES",
                      })}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkWoundEdgesId"
                      name="fkWoundEdgesId"
                      disabled={this.props.isEdit}
                      onChange={(e) => {
                        Common.withOutEventUpdateInputValueInState(
                          this.state.Resident.wound,
                          this,
                          this.state,
                          e,
                          "fkWoundEdgesId"
                        );
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        );
                      }}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundEdgesId
                      )}
                    >
                      <option value=""></option>
                      {this.state.WoundEdges}
                    </select>
                  </div>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="fkWoundSkinAndUlcerTreatment"
                    >
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.SKIN_ULCER_TREATMENT",
                      })}
                    </label>

                    <Select
                      className="form-control form-control-sm"
                      multiple
                      style={customStyles}
                      MenuProps={MenuProps}
                      disabled={this.props.isEdit}
                      value={
                        this.state.Resident.wound
                          .woundSkinAndUlcerTreatmentIds ??
                        this.state.selectedWoundSkinAndUlcerTreatmentIds
                      }
                      onChange={(e) => {
                        this.setState({
                          selectedWoundSkinAndUlcerTreatmentIds: e.target.value,
                        });
                      }}
                      input={<Input id="select-multiple" disableUnderline />}
                    >
                      {this.state.SkinAndUlcerTreatment.map((item) => {
                        console.log(item);
                        return (
                          <MenuItem key={item.key} value={item.key}>
                            {item.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="col-sm-3 col-md-3 col-lg-12 col-xl-12">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="fkDressingChangeFrequencyId"
                      >
                        {this.intl.formatMessage({
                          id: "NEW_WOUND_ASSESSMENT.FREQUENCY_OF_CHANGE",
                        })}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="fkDressingChangeFrequencyId"
                        required
                        disabled={this.props.isEdit}
                        name="fkDressingChangeFrequencyId"
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident.wound,
                            e,
                            this,
                            this.state
                          )
                        }
                        value={Common.replaceNullWithString(
                          this.state.Resident.wound.fkDressingChangeFrequencyId
                        )}
                      >
                        <option value=""></option>
                        {this.state.WoundDressingChangeFrequency}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="TreatmentDescription"
                    >
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.TREATMENT_DESCRIPTION",
                      })}
                    </label>
                    <textarea
                      className="form-control"
                      name="treatment"
                      disabled={this.props.isEdit}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.wound.treatment
                      )}
                      id="Treatment"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="GeneralComment">
                      {this.intl.formatMessage({
                        id: "NEW_WOUND_ASSESSMENT.GENERAL_COMMENT",
                      })}
                    </label>
                    <textarea
                      className="form-control"
                      name="comments"
                      disabled={this.props.isEdit}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.wound.comments
                      )}
                      id="comments"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>

              {this.props.canView ? (
                <div className="d-flex justify-content-end py-3">
                  <Button
                    variant="success"
                    disabled={this.props.isEdit}
                    className="mr-5 px-10"
                    size="sm"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    {this.intl.formatMessage({
                      id: "NEW_WOUND_ASSESSMENT.SUBMIT",
                    })}
                  </Button>
                </div>
              ) : null}
            </fieldset>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader type="Puff" color="#00BFFF" height={50} width={50} />
          </div>
        )}
      </>
    );
  }

  renderProductSelectionForm() {
    return (
      <>
        <ProductSelection
          ref={(ref) => (this.productSelection = ref)}
          primaryDressing=""
          secondaryDressing=""
          saleNote=""
          frequencyId={null}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <Form validated={this.state.formValidated}>
          {this.renderWoundForm()}
          {this.state.showProduct ? this.renderProductSelectionForm() : null}
        </Form>
      </>
    );
  }
}

export default injectIntl(NewWoundAssessment);
